import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useAppointmentDisplayStyles = makeStyles((theme: Theme) => {
  return {
    appointmentContainer: {
      margin: 16,
    },
    appointmentPanel: {
      height: 55,
      margin: '0 auto',
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: 'none',

      '&>div': {
        backgroundColor: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    appointment: {
      fontSize: 16,
    },
    appointmentIcon: {
      marginRight: theme.spacing(1),
      marginBottom: -6,
    },
    appointmentIconSelected: {
      color: theme.palette.primary.dark,
    },
    appointmentIconUnselected: {
      color: theme.palette.error.dark,
    },
    appointmentText: {
      '&>div': {
        display: 'inline-block',
      }
    }
  };
});