/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-operators */
import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { type WithTheme, withTheme } from '@material-ui/core/styles';
import type { Address, Countries } from 'types/Types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/TextField/TextField';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import { states } from '../../data/states';

export interface AddressInputExteranalProps {
  address?: Address,
  stacked?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  showAddress2?: boolean,
  onChange: (key: string, value: string, address: Address) => void,
  onBlur?: (key: string, value: string, address: Address) => void,
  className?: string,
  showCountry?: boolean,
  allowedCountries?: Countries[],
  additionalCountries?: { value: Countries, label: string, image: string }[],
  error?: boolean,
}

interface AddressInputProps extends AddressInputExteranalProps, WithTheme {
  t: (s: string, b?: any) => string
}

class AddressInput extends React.Component<AddressInputProps, Address> {
  constructor(props: AddressInputProps) {
    super(props);

    this.state = {
      address1: props.address && props.address.address1 || '',
      address2: props.address && props.address.address2 || '',
      city: props.address && props.address.city || '',
      state: props.address && props.address.state || '',
      zip: props.address && props.address.zip || '',
      country: props.address && props.address.country || 'USA',
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.address && this.props.address && (prevProps.address.address1 !== this.props.address.address1)) {
      this.setState({ address1: this.props.address.address1 || '' });
    }

    if (prevProps.address && this.props.address && prevProps.address.address2 !== this.props.address.address2) {
      this.setState({ address2: this.props.address.address2 || '' });
    }

    if (prevProps.address && this.props.address && prevProps.address.city !== this.props.address.city) {
      this.setState({ city: this.props.address.city || '' });
    }

    if (prevProps.address && this.props.address && prevProps.address.state !== this.props.address.state) {
      this.setState({ state: this.props.address.state || '' });
    }

    if (prevProps.address && this.props.address && prevProps.address.zip !== this.props.address.zip) {
      this.setState({ zip: this.props.address.zip || '' });
    }

    if (prevProps.address && this.props.address && prevProps.address.country !== this.props.address.country) {
      this.setState({ country: this.props.address.country || 'USA' });
    }
  };

  getFullAddress = (address1, address2, city, state, zip) => {
    const values: any[] = [];

    if (address1) values.push(address1);
    if (address2) values.push(address2);
    if (city) values.push(city);
    if (state) values.push(state);
    if (zip) values.push(zip);

    return values.join(', ');
  };

  onChange = (key: string, value) => {
    this.setState({ [key]: value } as Address, () => {
      const { onChange } = this.props;

      if (onChange) {
        onChange(key, value, { ...this.state });
      }
    });
  };

  onBlur = (key: string, value) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(key, value, { ...this.state });
    }
  };

  render() {
    const {
      stacked, readOnly, disabled, showAddress2, showCountry, allowedCountries, additionalCountries, error, t,
    } = this.props;
    const {
      address1, address2, city, state, zip, country,
    } = this.state;

    const sizes: {
      grid: 2 | 0,
      address1: 12 | 4 | 6,
      address2: 12 | 4,
      city: 12 | 2 | 3,
      state: 4 | 1 | 1,
      zip: 8 | 1 | 2,
    } = {
      grid: stacked ? 2 : 0,
      address1: stacked ? 12 : showAddress2 ? 4 : 6,
      address2: stacked ? 12 : 4,
      city: stacked ? 12 : showAddress2 ? 2 : 3,
      state: stacked ? 4 : showAddress2 ? 1 : 1,
      zip: stacked ? 8 : showAddress2 ? 1 : 2,
    };

    return (
      <Grid container spacing={sizes.grid}>
        <Grid item xs={sizes.address1} style={{ display: 'flex', alignItems: 'end' }}>
          {showCountry
            && (
              <CountrySelect
                readOnly={readOnly}
                disabled={disabled}
                value={country}
                error={error}
                allowedCountries={allowedCountries}
                additionalCountries={additionalCountries}
                onChange={(event: any) => this.onChange('country', event.target.value)}
                onBlur={(event: any) => this.onBlur('country', event.target.value)}
              />
            )}
          <TextField
            label={t('Address 1')}
            InputProps={{ readOnly }}
            disabled={disabled}
            value={address1}
            error={error}
            onChange={(event: any) => this.onChange('address1', event.target.value)}
            onBlur={(event: any) => this.onBlur('address1', event.target.value)}
            flex={1}
          />
        </Grid>
        {showAddress2
          && (
            <Grid item xs={sizes.address2}>
              <TextField
                label={t('Address 2')}
                InputProps={{ readOnly }}
                disabled={disabled}
                value={address2}
                error={error}
                onChange={(event) => this.onChange('address2', event.target.value)}
                onBlur={(event: any) => this.onBlur('address2', event.target.value)}
              />
            </Grid>
          )}
        <Grid item xs={sizes.city} sm={stacked ? 6 : undefined}>
          <TextField
            label={t('City')}
            InputProps={{ readOnly }}
            disabled={disabled}
            value={city}
            error={error}
            onChange={(event) => this.onChange('city', event.target.value)}
            onBlur={(event: any) => this.onBlur('city', event.target.value)}
          />
        </Grid>
        <Grid item xs={sizes.state} sm={stacked ? 2 : undefined}>
          <TextField
            select
            label={t(country === 'USA' ? 'State' : 'Province')}
            readOnly={readOnly}
            disabled={disabled}
            value={state}
            error={error}
            onChange={(event) => this.onChange('state', event.target.value)}
            onBlur={(event: any) => this.onBlur('state', event.target.value)}
          >
            {states.filter((item) => item.country === country).map((option) => (
              <MenuItem key={option.value} value={option.value}>{stacked ? option.label : option.value}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={sizes.zip} sm={stacked ? 4 : undefined}>
          <TextField
            label={t(country === 'USA' ? 'Zip' : 'Postal Code')}
            InputProps={{ readOnly }}
            disabled={disabled}
            value={zip}
            error={error}
            onChange={(event) => this.onChange('zip', event.target.value)}
            onBlur={(event) => this.onBlur('zip', event.target.value)}
          />
        </Grid>
      </Grid>
    );
  }
}

// export default withTheme(AddressInput);

const AddressInputER = withTheme(AddressInput);

export default (props: AddressInputExteranalProps) => {
  const { t } = useTranslation();

  return <AddressInputER {...props} t={t} />;
};
