import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { Autorenew as AutorenewIcon, List as ListIcon } from '@material-ui/icons';
import { upperFirst } from 'lodash';
import type { ThemeType } from 'types/ThemeType';
import type { AccountNumbers, Address } from 'types/Types';
import AccountAvatar, { getAccountTypeDesc } from 'components/AccountAvatar/AccountAvatar';
import Button from 'components/Button/Button';
import { usePhoneView } from '../../hooks/usePhoneView';

export interface LocationAvatarProps {
  className?: string,
  variant?: 'standard' | 'dropdown',
  name: string,
  role?: string,
  locationType?: 'fleet' | 'dealer',
  avatarPosition?: 'left' | 'right' | 'none',
  accountType: 'PC' | 'HO' | 'BT' | 'ST' | 'parentCompany' | 'homeOffice' | 'billTo' | 'shipTo',
  accountDisplay?: 'full' | 'short',
  accountNumbers?: AccountNumbers,
  displaySwitchAccount?: boolean,
  address?: Address,
  onSwitchAccount?: (event?: React.MouseEvent) => void,
  readOnly?: boolean,
  style?: React.CSSProperties,
  header?: React.ReactElement | React.ReactElement[] | null,
  footer?: React.ReactElement | React.ReactElement[] | null,
  dbaName?: string,
  storeNumber?: string,
  avatarOnly?: boolean,
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: () => ({
    minWidth: 200,
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flex: '1 1 0 %',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'default',
  }),
  text: (props: LocationAvatarProps) => ({
    flex: 1,
    paddingLeft: props.avatarPosition === 'left' ? theme.spacing(1) : 'inherit',
    paddingRight: props.avatarPosition === 'right' ? theme.spacing(1) : 'inherit',
    lineHeight: '12px',
  }),
  name: (props: LocationAvatarProps) => ({
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '14px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
    color: theme.palette.text.primary,
  }),
  description: (props: LocationAvatarProps) => ({
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: props.avatarPosition === 'right' ? 'right' : 'left',
  }),
  locationType: {
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    fontSize: '0.9em',
    display: 'block',
  },
  bullet: {
    '&:not(:last-child):after': {
      content: '\' • \'',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  popper: {
    zIndex: theme.zIndex.drawer,
  },
  PC: {
    backgroundColor: `${theme.palette.secondary.main}, 0.5`,
    color: theme.palette.secondary.contrastText,
  },
  HO: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  BT: {
    backgroundColor: theme.palette.default?.main,
    color: theme.palette.default?.contrastText,
  },
  ST: {
    backgroundColor: `${theme.palette.secondary.main}, 0.5`,
    color: theme.palette.success.contrastText,
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
  right: {
    textAlign: 'right',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: '4px',
  },
  info: {
    padding: '15px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    backgroundColor: theme.palette.background.default,
  },
}));

function getLocationTypeDesc(locationType: LocationAvatarProps['locationType']) {
  if (locationType === 'dealer') return 'Service Provider';
  return upperFirst(locationType);
}

const getAddress = (address, short = false) => {
  let ADDRESS: string[] = [];

  if (address.address1) ADDRESS.push(address.address1);
  if (address.address2) ADDRESS.push(address.address2);
  if (address.city) ADDRESS.push(address.city);
  if (address.state) ADDRESS.push(address.state);
  if (address.zip) ADDRESS.push(address.zip);

  if (short) ADDRESS = [ADDRESS[0]] || [''];

  return ADDRESS.join(', ')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0)
      .toUpperCase() + s.substring(1))
    .join(' ');
};

export default function LocationAvatar(props: LocationAvatarProps) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const phoneView = usePhoneView();

  const {
    displaySwitchAccount,
    className,
    accountDisplay,
    avatarPosition = 'left',
    variant = 'standard',
    name,
    dbaName,
    storeNumber,
    address,
    locationType,
    accountType,
    style = {},
    accountNumbers,
    role,
    onSwitchAccount = () => {
    },
    readOnly,
    avatarOnly,
  } = props;
  const accountNumber = accountNumbers
    ? accountNumbers.mdm || accountNumbers.shipTo || accountNumbers.billTo || accountNumbers.homeOffice || accountNumbers.parentCompany || ''
    : '';

  return (
    <>
      <AccountAvatar
        key={name}
        name={name}
        accountDisplay={accountDisplay}
        className={className}
        accountType={accountType}
        address={address || {}}
        accountNumbers={accountNumbers}
        avatarPosition={avatarPosition}
        style={{
          width: 'auto',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          ...style,
        }}
        avatarOnly={avatarOnly}
      >
        <>{props.header ? props.header : null}</>
        <div
          style={{
            minWidth: 150,
            width: phoneView ? 'calc(100vw + 10px)' : 'auto',
          }}
        >
          <span style={{ textTransform: 'capitalize' }}>
            <div className={classes.info}>
              <span className={classes.name}>
                <b>{name}</b>
              </span>
              <br />
              <span className={classes.locationType}>
                {t(getLocationTypeDesc(locationType))}
              </span>
            </div>
            <Divider />
            <div style={{ padding: 15 }}>
              {dbaName ? (
                <>
                  <span className={classes.label}>{t('DBA Name: ')}</span>
                  {dbaName}
                  <br />
                </>
              ) : null}

              {address
                && (address.address1
                  || address.address2
                  || address.city
                  || address.state
                  || address.zip) && (
                  <>
                    <span className={classes.label}>{t('Address:')}</span>
                    <span>{getAddress(address, phoneView)}</span>
                    <br />
                  </>
              )}
              {address && address.country ? (
                <>
                  <span className={classes.label}>{t('Country:')}</span>
                  <span>
                    {t(
                      `COUNTRY_CODE|${(address.country || '').substring(0, 2)}`,
                    )}
                  </span>
                  <br />
                </>
              ) : null}
              <span className={classes.label}>{t('Account:')}</span>
              <span>
                {` ${t(getAccountTypeDesc(accountType))} `}
                {accountNumber ? `#${accountNumber}` : null}
              </span>

              <br />
              {storeNumber ? (
                <>
                  <span className={classes.label}>{t('Store #:')}</span>
                  <span>{storeNumber}</span>
                  <br />
                </>
              ) : null}
              {/* {t('Timezone')}: <br/> */}
              {role ? (
                <>
                  <span className={classes.label}>{t('Role:')}</span>
                  <span>{t(role)}</span>
                  <br />
                </>
              ) : null}
              {props.footer ? props.footer : null}
              {displaySwitchAccount ? (
                <Button
                  onClick={onSwitchAccount}
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{
                    marginTop: 10,
                    width: '100%',
                    display: 'block',
                  }}
                >
                  {!readOnly ? (
                    <>
                      <AutorenewIcon
                        style={{
                          width: 19,
                          height: 19,
                          marginTop: 0,
                          marginBottom: -5,
                          marginRight: 4,
                        }}
                      />
                      {t('Switch Account')}
                    </>
                  ) : (
                    <>
                      <ListIcon
                        style={{
                          width: 19,
                          height: 19,
                          marginTop: 0,
                          marginBottom: -5,
                          marginRight: 4,
                        }}
                      />
                      {t('View Accounts')}
                    </>
                  )}
                </Button>
              ) : null}
            </div>
          </span>
        </div>
      </AccountAvatar>
    </>
  );
}

LocationAvatar.defaultProps = {
  style: {},
  variant: 'standard',
  avatarPosition: 'left',
};
