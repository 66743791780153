import React from 'react';
import { useSubtitleStyles } from './styles';
import { TitleItemType } from './types';

export interface ISubtitleProps {
  subtitle: TitleItemType;
  visible?: boolean;
}

export const Subtitle = (props: ISubtitleProps) => {
  const {
    subtitle,
    visible = true,
  } = props;
  const classes = useSubtitleStyles();
  if (!visible) return null;
  if (typeof subtitle !== 'string') return <>{subtitle}</>;
  return (
    <span className={classes.subtitle}>
      { subtitle }
    </span>
  );
};
