import React from 'react';
import Autocomplete, { type AutocompleteProps } from 'components/Autocomplete/Autocomplete';

export interface SelectTypeaheadProps extends Omit<AutocompleteProps, 'value' | 'options'>{
  placeholder?: string,
  strictness?: 0 | 1 | 2 | 3 | 4,
  id?: string,
  values?: string[],
  value?: string,
  readOnly?: boolean
  suggestions?: {
    label: string,
    value: string,
  }[],
  className?: string,
  label: string,
  variant?: 'default'|'standard'|'outlined'|'filled',
  multiple?: boolean;
}

export default function SelectTypeahead(props: SelectTypeaheadProps) {
  const {
    suggestions = [], values, value, variant = 'standard', ...otherProps
  } = props;

  if (props.multiple) {
    return (
      <Autocomplete
        variant={variant}
        checkboxes
        options={suggestions}
        value={value ? [value] : undefined}
        {...otherProps}
      />
    );
  }

  return (
    <Autocomplete
      variant={variant}
      options={suggestions}
      value={values}
      {...otherProps}
    />
  );
}

SelectTypeahead.defaultProps = {
  suggestions: [],
  strictness: 1,
  placeholder: '',
  variant: 'standard',
};
