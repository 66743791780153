import { ThemeSwitch } from '../ThemeSwitch';
import { withThemeOptions } from './withThemeOptions';

const MAESTRO_THEME_OPTIONS = {
  on: {
    value: 'maestroLight',
    switchToLabel: 'Switch to Light Theme',
  },
  off: {
    value: 'maestroDark',
    switchToLabel: 'Switch to Dark Theme',
  },
} as const;

/**
 * ThemeSwitch with Maestro Light and Dark themes preset as theme options
 */
const MaestroThemeSwitch = withThemeOptions({
  themeOptions: MAESTRO_THEME_OPTIONS,
})(ThemeSwitch);

type MaestroThemeSwitchProps = Parameters<typeof MaestroThemeSwitch>[0];

export {
  MaestroThemeSwitch,
  type MaestroThemeSwitchProps,
};
