/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  Avatar as MuiAvatar, Badge as MuiBadge, Collapse, Paper,
} from '@material-ui/core';
import clsx from 'clsx';
import Moment from 'react-moment';
import type { ThemeType } from 'types/ThemeType';
import type { SpacingType } from 'types/Types';
import { useTranslation } from 'react-i18next';
import {
  Close,
  Contacts,
  MailOutlined,
  NoteOutlined,
  PeopleOutline,
  ReceiptOutlined,
  Room,
} from '@material-ui/icons';
import IconButton from 'components/IconButton/IconButton';

export type NotificationType = {
  _id?: string,
  userId?: string,
  tenantId?: number,
  notifiableId?: string,
  notifiableType?: string,
  message: {
    html?: string,
    text?: string,
  },
  createdAt?: string,
  updatedAt?: string,
  viewedAt?: string,
  deletedAt?: string,
};

export interface NotificationProps {
  isDeleted?: boolean,
  /** ClassName to apply to the component. */
  className?: string,
  /** The notification to render inside the component. */
  notification: NotificationType,
  /** What is the default internal padding of the component. */
  spacing?: SpacingType,
  /** The callback function for when the message has been updated */
  onUpdate?: (item: NotificationType, action: string, value: boolean) => void,
}

const NOTIFIABLE_TYPE_ICON = {
  user: <PeopleOutline />,
  order: <ReceiptOutlined />,
  note: <NoteOutlined />,
  location: <Room />,
  contact_subscription: <Contacts />,
  default: <MailOutlined />,
};

const useStyles = makeStyles((theme: ThemeType) => {
  const IS_DARK = theme.palette.type === 'dark';
  return {
    root: (props: any) => ({
      display: (props.isDeleted && 'none') || 'flex',
      backgroundColor: IS_DARK ? theme.palette.grey[900] : theme.palette.background.default,
      position: 'relative',
      borderLeft: '2px solid transparent',
      flexDirection: 'column',
      '&--unread': {
        borderLeft: '2px solid #6daeff',
        backgroundColor: IS_DARK ? theme.palette.background.default : 'rgb(232 244 255 / 50%)',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: '1px',
        width: '100%',
        borderBottom: `1px solid ${IS_DARK ? theme.palette.grey[800] : theme.palette.grey[300]}`,
      },
    }),
    timestamp: {
      flexGrow: 1,
      textAlign: 'right',
      lineHeight: 'normal',
      color: '#777777',
      fontSize: '11px',
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
    title: {
      margin: 0,
      padding: theme.spacing(0, 1, 0, 1),
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    display: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '12px',
      borderRadius: '5px',
      lineHeight: '16px',
      transition: 'all 500ms ease',
      backgroundColor: IS_DARK ? theme.palette.grey[800] : 'rgb(229 229 229 / 90%)',
      margin: '0px 15px 15px 15px',
      opacity: 1,
      maxHeight: '500px',
      padding: '6px',
      '& p': {
        margin: 0,
      },
      '& a': {
        color: theme.palette.primary.dark,
      },
    },
    html: {
      padding: theme.spacing(0, 1, 1, 1),
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      color: '#3c92dd',
      fontSize: '11px',
    },
    separator: {
      width: '100%',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      color: IS_DARK ? theme.palette.grey[800] : '#3c92dd',
      fontSize: '11px',
    },
    avatar: {
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      padding: '12px',
    },
    badge: {
      border: '1px solid white',
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      backgroundColor: '#3c92dd',
    },
    content: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      minWidth: '50px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    moreButton: {
      height: 20,
      width: 20,
      padding: 0,
      marginLeft: '10px',
      '&:hover': {
        background: 'none',
      },
    },
  };
});

export default function Notification(props: NotificationProps) {
  const {
    className,
    notification,
    spacing = 0,
    onUpdate,
    ...otherProps
  } = props;
  const isDeleted = notification && notification.deletedAt;
  const isRead = notification && notification.viewedAt;
  const classes = useStyles({
    ...props,
    isDeleted,
    isRead,
    spacing,
  });
  const { i18n } = useTranslation();
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleViewClick = () => {
    if (onUpdate) {
      onUpdate(notification, 'viewed', true);
    }
  };

  const handleDeleteClick = () => {
    const value = !isDeleted;

    if (onUpdate) {
      onUpdate(notification, 'deleted', value);
    }
  };
  const locale = i18n?.language?.replace('_', '-')
    .toLowerCase();

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <>
      <Paper
        elevation={0}
        square
        className={clsx(className, classes.root, {
          [`${classes.root}--unread`]: !isRead,
        })}
        onClick={() => {
          handleViewClick();
          setCollapse(!collapse);
        }}
        {...otherProps}
      >
        <div className={classes.wrapper}>
          <div className={classes.avatar}>
            <MuiBadge
              variant="dot"
              color="primary"
              overlap="circle"
              invisible={!!isRead}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              classes={{ badge: clsx(classes.badge) }}
            >
              <MuiAvatar>
                {notification.notifiableType
                && NOTIFIABLE_TYPE_ICON[notification.notifiableType]
                  ? NOTIFIABLE_TYPE_ICON[notification.notifiableType]
                  : NOTIFIABLE_TYPE_ICON.default}
              </MuiAvatar>
            </MuiBadge>
          </div>
          <div className={classes.content}>
            <div className={clsx(classes.timestamp)}>
              <Moment fromNow locale={locale}>
                {notification.createdAt}
              </Moment>
              <IconButton
                data-testid="delete-notification"
                color="primary"
                onClick={(e) => {
                  handleDeleteClick();
                  handleClose(e);
                }}
                className={classes.moreButton}
                disableRipple
              >
                <Close
                  style={{
                    position: 'relative',
                    color: theme.palette.grey[500],
                    height: 15,
                    right: '8px',
                  }}
                />
              </IconButton>
            </div>
            <h6 className={clsx(classes.title)}>
              {notification.message.text || ''}
            </h6>
          </div>
        </div>
        <Collapse in={collapse}>
          {notification.message.html && (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              className={classes.display}
              dangerouslySetInnerHTML={{
                __html: notification.message.html || '',
              }}
            />
          )}
        </Collapse>
      </Paper>
    </>
  );
}

Notification.defaultProps = {
  spacing: 0,
};
