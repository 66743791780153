import React, { useMemo } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useSelectDateTimeStyles } from './useSelectDateTimeStyles';
import { HourButton } from './HourButton';

export interface ISelectDateTimeProps {
  date: Date|number|null;
  setDate: (date:Date) => void;
  availableHours?: HoursOfOperationsType;
  isChosen?: (choosed: boolean) => void;
  translateFunction?: (str: string) => string;
  readOnly?: boolean;
}

export const WeekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;

export type WeekDayType = typeof WeekDays[number];

export type HoursOfOperationsDayType = {
  end: string;
  begin: string;
};

export type HoursOfOperationsType = {
  [K in WeekDayType]?: HoursOfOperationsDayType | null;
};

type IsClosedFn = (date: Date | null) => boolean;
type IsClosedFnFactory = (availableHours: ISelectDateTimeProps['availableHours']) => IsClosedFn;

const createIsClosedFn: IsClosedFnFactory = (availableHours) => {
  if (!availableHours) {
    return (d) => false;
  }

  return (d) => {
    if (!d) return true;

    if (moment(d) < moment().endOf('day')) return true;

    const weekDay = WeekDays[d.getDay()];
    if (!availableHours[weekDay]) return true;

    return false;
  };
};

const DAY_FIRST_HOUR = 0;
const DAY_LAST_HOUR = 24;

const getTimes = (day?: HoursOfOperationsDayType) => {
  const begin = day ? parseInt(day.begin.split(':')[0], 10) : DAY_FIRST_HOUR;
  const end = day ? parseInt(day.end.split(':')[0], 10) : DAY_LAST_HOUR;

  const timesArr = new Array(end - begin).fill(0).map((i, idx) => {
    return begin + idx;
  });

  const pmIdx = timesArr.findIndex((time) => {
    return time >= 12;
  });

  const amHours = timesArr.slice(0, pmIdx);
  const pmHours = timesArr.slice(pmIdx).map((time) => {
    if (time === 12) return time;

    return time - 12;
  });

  return {
    am: amHours,
    pm: pmHours,
  };
};

const areHoursSpecified = (availableHours: ISelectDateTimeProps['availableHours']) => {
  return !!availableHours && !isEmpty(availableHours);
}

const generateHourItemKey = (hour: number, ampm: string) => {
  return `HOURITEM_${hour}${ampm}`;
}

const SelectDateTime = (props: ISelectDateTimeProps) => {
  const {
    date: propDate,
    setDate = () => {},
    availableHours,
    readOnly,
    translateFunction: t = (str) => str,
  } = props;

  const date = typeof propDate === 'number' ? new Date(propDate) : propDate;

  const classes = useSelectDateTimeStyles();

  const selectedHours = useMemo(() => {
    const day = date?.getDay();

    return (availableHours && day) ? availableHours[WeekDays[day]] : undefined;
  }, [date, availableHours]);

  const timesForDay = useMemo(() => {
    return getTimes(selectedHours!);
  }, [selectedHours]);

  const isClosedOn = createIsClosedFn(availableHours);
  const hoursAreSpecified = areHoursSpecified(availableHours);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container
        className={classes.selectDateTimeContainer}
      >
        <Grid item xs={6} className={classes.datePicker}>
          <DatePicker
            shouldDisableDate={(d) => {
              return !!readOnly || (hoursAreSpecified && isClosedOn(d as unknown as Date));
            }}
            autoOk
            variant="static"
            onChange={(e) => {
              if (!e) return;
              setDate(e as unknown as Date);
            }}
            value={date}
            disablePast
            disableToolbar
            animateYearScrolling
          />
        </Grid>
        <Grid item container xs={6}>
          <Grid container>
            <Grid item xs={6} className={classes.ampmColumn}>
              {date && (
              <>
                <div className={classes.ampmTitle}>
                  <b>{t('AM')}</b>
                </div>
                <Grid container direction="column">
                  {timesForDay?.am.map((time) => {
                    const isSelected = date.getMinutes() === 0 && date.getSeconds() === 0 && date.getHours() === time;

                    return (
                      <Grid item xs={12} key={generateHourItemKey(time, 'am')}>
                        <HourButton
                          date={date}
                          time={time}
                          isSelected={isSelected}
                          ampm="am"
                          setDate={setDate}
                          className={classes.hourButton}
                          disabled={!isSelected && readOnly}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </>
              )}
            </Grid>
            <Grid item xs={6} className={classes.ampmColumn}>
              {date && (
              <>
                <div className={classes.ampmTitle}>
                  <b>{t('PM')}</b>
                </div>
                <Grid container direction="column">
                  {timesForDay?.pm.map((time) => {
                    const isSelected = (date.getMinutes() === 0 && date.getSeconds() === 0) && date.getHours() === (time === 12 ? time : time + 12);

                    return (
                      <Grid item xs={12} key={generateHourItemKey(time, 'pm')}>
                        <HourButton
                          date={date}
                          time={time}
                          isSelected={isSelected}
                          ampm="pm"
                          setDate={setDate}
                          className={classes.hourButton}
                          disabled={!isSelected && readOnly}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export { SelectDateTime };