/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import PreventTransitionDialog from './PreventTransitionDialog';

declare global {
  interface Window { ptpChangesMade: boolean; }
}
export interface PreventTransitionPromptExternalProps {
  when: boolean
  title?: string
  message?: string
  forceOpen?: boolean
  handleDiscard?: (loc?: string) => void
  handleCancel?: () => void
  discardLabel?: string
  cancelLabel?: string
  fromTab?: boolean
}
interface PreventTransitionPromptProps extends PreventTransitionPromptExternalProps, RouteComponentProps {
  t: (s: string, b?: any) => string
}

type AllowTransitionCallback = (allow: boolean) => void;
interface PreventTransitionPromptState {
  open: boolean
  desiredTargetLocation: string
  allowTransitionCallback: null | AllowTransitionCallback
}

let instanceShow: null | ((cb: AllowTransitionCallback) => void) = null;
if (window.ptpChangesMade === undefined) window.ptpChangesMade = false;

class PreventTransitionPrompt extends React.Component<PreventTransitionPromptProps, PreventTransitionPromptState> {
  private unblock: Function;

  private topLevelInstance: boolean;

  constructor(props: PreventTransitionPromptProps) {
    super(props);
    this.state = { open: false, desiredTargetLocation: '', allowTransitionCallback: null };
    this.unblock = () => { };
    this.topLevelInstance = instanceShow === null;
  }

  shouldComponentUpdate(nextProps: PreventTransitionPromptProps) {
    if (!this.topLevelInstance) return false;
    if (this.props.when !== nextProps.when) window.ptpChangesMade = nextProps.when;
    return true;
  }

  /**
   * Attach global dialog trigger for this component
   * instance to our Symbol trigger
   */
  componentDidMount() {
    this.topLevelInstance = instanceShow === null;
    if (!this.topLevelInstance) return;
    window.ptpChangesMade = this.props.when;
    instanceShow = this.show;
    this.unblock = this.props.history.block((e) => {
      if (this.state.allowTransitionCallback) return false;
      if (!window.ptpChangesMade) return undefined;
      this.show((allowed) => {
        if (!allowed) return;
        this.props.history.push(e);
      });
      return false;
    });
  }

  /**
   * Ensure we clean up and remove the reference
   * from the global object
   */
  componentWillUnmount() {
    if (!this.topLevelInstance) return;
    instanceShow = null;
    window.ptpChangesMade = false;
    this.unblock();
  }

  render() {
    if (!this.topLevelInstance) {
      return null;
    }
    const {
      title,
      message,
      discardLabel,
      cancelLabel,
      forceOpen,
      t,
    } = this.props;
    const { open } = this.state;
    return (
      <PreventTransitionDialog
        open={open || forceOpen || false}
        handleCancel={this.handleCancel}
        handleClose={this.handleCancel}
        handleDiscard={this.handleDiscard}
        message={message || t('Do you want to discard those changes?')}
        discardLabel={discardLabel}
        cancelLabel={cancelLabel}
        title={title || t('You have unsaved changes...')}
      />
    );
  }

  /**
   * Show the dialog. Invoked primarily from React Router transition
   * handler getUserConfirmation.
   *
   * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
   */
  show = (allowTransitionCallback: AllowTransitionCallback) => {
    if (!this.props.when) {
      allowTransitionCallback(true);
      return;
    }
    this.setState({ open: true, allowTransitionCallback });
  };

  handleDiscard = async () => {
    this.setState({ open: false });
    if (this.props.handleDiscard) await this.props.handleDiscard(this.state.desiredTargetLocation);
    if (!(this.props.fromTab)) {
      this.props.history.push({ pathname: this.state.desiredTargetLocation });
      // If further notification about changing URL is required, add a call here.
    }
    if (this.state.allowTransitionCallback) {
      window.ptpChangesMade = false;
      const allowTC = this.state.allowTransitionCallback;
      await this.setState({ open: false, allowTransitionCallback: null });
      allowTC(true);
    }
  };

  handleCancel = async () => {
    this.setState({ open: false });
    if (this.state.allowTransitionCallback) {
      const allowTC = this.state.allowTransitionCallback;
      await this.setState({ open: false, allowTransitionCallback: null });
      allowTC(false);
    }
    if (this.props.handleCancel) this.props.handleCancel();
    // If further notification about changing URL is required, add a call here.
  };
}

export function hasUnsavedChanges() { return window.ptpChangesMade; }

export function checkTransitionAllowed(callback: AllowTransitionCallback) {
  if (!instanceShow) return callback(true);
  return instanceShow(callback);
}

window.onbeforeunload = () => {
  if (window.ptpChangesMade) return true;
  return null;
};

const PreventTransitionPromptER = (withRouter(PreventTransitionPrompt));

export default (props: PreventTransitionPromptExternalProps) => {
  const { t } = useTranslation();

  return <PreventTransitionPromptER {...props} t={t} />;
};
