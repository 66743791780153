import React from 'react';

declare global {
  interface Window {
    dataLayer: any;
    gtag: any;
  }
}

export function GoogleAnalytics({ children, googleAnalyticsId }:{ children: React.ReactChild, googleAnalyticsId: string }) {
  if (!window.gtag) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { window.dataLayer.push(arguments); };
    window.gtag('js', new Date());
    window.gtag('config', googleAnalyticsId);

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    document.body.appendChild(script);
  }

  window.gtag('set', 'page_path', window.location.pathname + window.location.search);
  window.gtag('event', 'page_view');
  
  return <>{children}</>;
}