import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import Select, { type SelectProps } from 'components/Select/Select';
import Tooltip from 'components/Tooltip/Tooltip';

import USA from '../../images/countries/USA.svg';
import CAN from '../../images/countries/CAN.svg';
import FRA from '../../images/countries/FRA.svg';
import GER from '../../images/countries/GER.svg';
// import MEX from '../images/countries/MEX.svg'

const avatarStyles = makeStyles(() => ({
  root: {
    height: '19px',
    width: '19px',
  },
  medium: {
    height: '30px',
    width: '30px',
  },
  large: {
    height: '40px',
    width: '40px',
  },
}));
type Country = 'USA'|'CAN'|'FRA'|'GER';
export interface CountrySelectProps extends SelectProps {
  allowedCountries?: Country[],
  additionalCountries?: { value: Country, label: string, image: string }[],
  size?: 'default'|'medium'|'large',
  readOnly?: boolean,
  disabled?: boolean,
  value?: string,
}

const countryList:{value: Country, label: string, image: string}[] = [
  { value: 'USA', label: 'United States of America', image: USA },
  { value: 'CAN', label: 'Canada', image: CAN },
  { value: 'FRA', label: 'France', image: FRA },
  { value: 'GER', label: 'Germany', image: GER },
  // { value: 'MEX', label: 'Mexico', image: MEX },
];
export default function CountrySelect(props: CountrySelectProps) {
  const avatarClasses = avatarStyles(props);
  const {
    allowedCountries = ['USA', 'CAN'], additionalCountries = [], size = 'default', ...otherProps
  } = props;
  const countries = countryList.filter(
    (country) => allowedCountries.includes(country.value),
  ).concat(additionalCountries.filter(
    (item) => !countryList.find(
      (country) => country.value === item.value,
    ),
  ));

  return (
    <Select {...otherProps} style={{ minWidth: '42px' }}>
      {countries.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Tooltip title={option.label} aria-label={option.label}>
            <Avatar
              alt={option.label}
              src={option.image}
              className={
                clsx(
                  avatarClasses.root,
                  size === 'medium' && avatarClasses.medium,
                  size === 'large' && avatarClasses.large,
                )
              }
            />
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
}
CountrySelect.defaultProps = {
  allowedCountries: ['USA', 'CAN'],
  additionalCountries: [],
  size: 'default',
};
