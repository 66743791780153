/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Drawer, Divider, List, Theme, IconButton, Hidden,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { Scrollbars } from 'react-custom-scrollbars-2';
import type { DrawerProps as MuiDrawerProps } from '@material-ui/core/Drawer';
import { Menu as MenuIcon } from '@material-ui/icons';
import { LegalDocs } from 'types/Types';
import Version from './Version';
import SidebarHeader from './SidebarHeader';
import { baseStyles } from './styles';
import { Item } from './Item';
import SidebarExternals from './SidebarExternals';
import Legal from './Legals';
import Copyrights from './Copyrights';
import VerticalFloating from './VerticalFloating';
import { usePhoneView } from '../../hooks/usePhoneView';

const useStyles = (isMobile) => makeStyles((theme: Theme) => ({
  transparentDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  drawerContainer: {
    position: 'relative',
  },
  frontDiv: {
    zIndex: 1,
    position: 'relative',
    bottom: 0,
    width: '100%',
    background: theme.palette.background.paper,
  },
  externalOpen: {
    position: 'absolute',
  },
  externalClose: {
    position: 'relative',
  },
  vertical: {
    width: '24px',
  },
  verticalShadow: {
    width: '3px',
    height: '100%',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  floatingButton: {
    position: 'absolute',
    padding: '0',
    width: '10px',
    minWidth: '24px',
    height: '24px',
    top: '28px',
    borderRadius: '12px',
    background: 'red',
    left: '228px',
    zIndex: 1200,
  },
  hamburgerMenu: {
    position: 'fixed',
    top: '8px',
    left: '10px',
    zIndex: 1202,
    color: theme.palette.primary.main,
  },
  mobileDrawer: {
    zIndex: 1203,
    position: 'fixed',
    marginTop: '-5rem',
  },
  drawerPaperMobile: {
    width: isMobile ? '75%' : '45%',
  },
}))();

export interface ItemType {
  label?: string,
  icon?: React.ReactElement,
  onClick?: (event?: React.MouseEvent) => void,
  selected?: boolean,
  divider?: boolean,
  heading?: boolean,
  position?: 'top' | 'bottom',
  to?: string,
  subitems?: ItemType[],
  open?: boolean,
}

export interface VersionType {
  application: string,
  version: string,
}

export interface SidebarProps extends MuiDrawerProps {
  className?: string,
  title?: string,
  drawerWidth?: number,
  items?: ItemType[],
  versions?: VersionType[],
  versionsLinkClick?: () => void;
  onShrink?: (event?: React.MouseEvent) => void,
  onGrow?: (event?: React.MouseEvent) => void,
  rememberMinifiedStatus?: boolean,
  externalLinksTitle?: string,
  externalLinks?: ItemType[],
  variant?: 'permanent' | 'persistent' | 'temporary',
  open?: boolean,
  position?: string,
  showLegalInformation?: boolean,
  showCopyright?: boolean,
  children?: React.ReactNode,
  execute?: string,
  termsOfUsePdf?: string,
  privacyPolicyPdf?: string,
  termsOfServicePdf?: string,
  onLegalLinkClick?: (link: LegalDocs) => void
}

declare namespace Sidebar {
  export type Item = ItemType;
  export type Version = VersionType;
}

/**
 ## Items Property

 The `items` property is an object defined as

 {
  label?: string,
  icon?: React.ReactElement,
  onClick?: (event?: React.MouseEvent) => void,
  selected?: boolean,
  heading?: boolean,
  divider?: boolean,
  position?: string,
}

 For a normal menu item, the `label` and `onClick` properties should be specified. For best use, the `icon` should also be present.

 You can choose an item to be on top or bottom of the sidebar using the position property and setting it's value to "top" or "bottom".

 If a divider is wanted in the menu, the object should just contain the `divider` property set to true. All other properties will be ignored.

 A new heading item can be created by passing `heading` property set to true. For best use, the `label` should also be preset to name the section.

 ## Versions Property

 The `versions` property is an object defined as

 {
  application: string,
  version: string,
}

 The **Sidebar** will present any number of version information to the user.
 When in the expanded mode they will be stacked on a list on the bottom of the bar.
 When collapsed they will be available via a tool tip on the information icon in the bottom of the bar.

 The `application` property can be any application name, but should be short enough to not cause a wrapping issue.

 The `version` property should be presented in `major.minor.build` format. When it is displayed a version prefix
 **v** will be prepended onto the version string.
 */
// eslint-disable-next-line no-redeclare
function Sidebar(props: SidebarProps) {
  const phoneView = usePhoneView('xs');
  const tableView = usePhoneView('sm');
  const divClasses = useStyles(phoneView);

  const {
    title,
    items,
    versions,
    onGrow,
    onShrink,
    showLegalInformation,
    showCopyright,
    children,
    execute,
    termsOfUsePdf,
    termsOfServicePdf,
    privacyPolicyPdf,
    onLegalLinkClick,
    versionsLinkClick,
  } = props;

  let initialMini = false;
  if (props.rememberMinifiedStatus) {
    initialMini = !!Cookies.get('sidebar-minimized');
    if (initialMini && onShrink) onShrink();
  }

  const [mini, setMini] = useState(initialMini);
  const classes = baseStyles({
    ...props,
    mini,
  });
  if (props.rememberMinifiedStatus) Cookies.set('sidebar-minimized', mini ? 'true' : '');

  const toggleMini = (event) => {
    const value = mini;
    setMini(!value);
    if (value && onGrow) onGrow(event);
    if (!value && onShrink) onShrink(event);
  };

  const open = tableView || !mini;

  const topItems = items ? items.filter((i) => i.position !== 'bottom') : undefined; // simulate default top
  const bottomItems = items ? items.filter((i) => i.position === 'bottom') : undefined;
  const showExternals = props.externalLinks && props.externalLinks.length > 0;
  const [hideActions, setHideActions] = useState(false);
  const [showExternalLinks, setShowExternalLinks] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    if (execute) {
      toggleMini(!!execute);
    }
  }, [execute]);

  useEffect(() => {
    const root = document.documentElement;
    if (tableView) {
      root.style.setProperty('--margin-sidebar', '30px');
    } else {
      setMobileOpen(false);
      root.style.setProperty('--margin-sidebar', '0px');
    }
  }, [tableView]);

  const handleDrawerToggleMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  const setShowLinks = (c: boolean) => {
    setHideActions(c);
    setShowExternalLinks(c);
  };

  useEffect(() => {
    if (!mobileOpen) {
      setShowLinks(false);
    }
  }, [mobileOpen]);

  useEffect(() => () => document.documentElement.style.setProperty('--margin-sidebar', '0px'), []);

  const drawerContent = (
    <div className={divClasses.transparentDiv}>
      <div style={{
        zIndex: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      >
        {title && (
          <>
            <SidebarHeader
              title={title}
              open={open}
              handleDrawerClose={toggleMini}
              handleDrawerOpen={toggleMini}
            />
            <Divider />
          </>
        )}
        {children}
        <Scrollbars
          autoHide
          autoHideTimeout={400}
        >
          {topItems && topItems.length > 0 && (
            <>
              {hideActions && (
                <div style={{
                  filter: 'alpha(opacity=30)',
                  opacity: 0.30,
                }}
                >
                  <List
                    style={{
                      paddingTop: 0,
                      width: '100%',
                      flexGrow: 1,
                      overflowY: 'auto',
                    }}
                    dense
                  >
                    {topItems.map((item, i) => (
                      <Item
                        disabled
                        key={`${item.label}_${i}`}
                        item={item}
                        open={open}
                        classes={classes}
                        closeSidebar={tableView ? handleDrawerToggleMobile : undefined}
                      />
                    ))}
                  </List>
                </div>
              )}
              {!hideActions && (
                <List
                  style={{
                    paddingTop: 0,
                    width: '100%',
                    flexGrow: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  dense
                >
                  {topItems.map((item, i) => (
                    <Item
                      key={`${item.label}_${i}`}
                      item={item}
                      open={open}
                      classes={classes}
                      closeSidebar={tableView ? handleDrawerToggleMobile : undefined}
                    />
                  ))}
                </List>
              )}
            </>
          )}
        </Scrollbars>
        <div className={clsx(divClasses.frontDiv, {
          [divClasses.externalOpen]: hideActions,
          [divClasses.externalClose]: !hideActions,
        })}
        >
          {bottomItems && bottomItems.length > 0 && (
            <List
              style={{
                paddingBottom: 0,
                width: '100%',
              }}
              dense
            >
              {bottomItems.map((item, i) => (
                <Item
                  key={`${item.label}_${i}`}
                  item={item}
                  open={open}
                  classes={classes}
                  closeSidebar={tableView ? handleDrawerToggleMobile : undefined}
                />
              ))}
            </List>
          )}
          {showExternals && (
            <SidebarExternals
              showLinks={showExternalLinks}
              setShowLinks={setShowLinks}
              open={open}
              items={props.externalLinks}
              title={props.externalLinksTitle}
              closeSidebar={tableView ? handleDrawerToggleMobile : undefined}
            />
          )}
          {versions && versions.length > 0 && (
            <Version
              ui={versions[0] ? versions[0].version : null}
              api={versions[1] ? versions[1].version : null}
              open={open}
              versionsLinkClick={versionsLinkClick}
            />
          )}
          {open && showCopyright && (
            <Copyrights open={open} />
          )}
          {showLegalInformation && (
            <Legal
              open={open}
              termsOfUsePdf={termsOfUsePdf}
              termsOfServicePdf={termsOfServicePdf}
              privacyPolicyPdf={privacyPolicyPdf}
              onLegalLinkClick={(link: 'termsOfUse' | 'termsOfService' | 'privacyPolicy') => { if (onLegalLinkClick) onLegalLinkClick(link); }}
            />
          )}
          {!open && showCopyright && (
            <Copyrights open={open} />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={divClasses.wrapper}>
      <Hidden mdUp implementation="css">
        <IconButton edge="start" color="inherit" aria-label="menu" className={divClasses.hamburgerMenu} onClick={handleDrawerToggleMobile}>
          <MenuIcon />
        </IconButton>
      </Hidden>

      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggleMobile}
          classes={{
            paper: divClasses.drawerPaperMobile,
          }}
          className={divClasses.mobileDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, divClasses.drawerContainer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.drawerPaper]: true,
            }),
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <VerticalFloating open={open} handler={toggleMini} hasTitle={!!title} />
      </Hidden>
    </div>
  );
}

Sidebar.defaultProps = {
  variant: 'permanent',
  open: true,
  title: '',
  drawerWidth: 240,
  items: [],
  versions: [],
  rememberMinifiedStatus: true,
  position: 'top',
  showLegalInformation: false,
  showCopyright: false,
};

export default Sidebar;
