import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarTodayOutlined } from '@material-ui/icons';
import moment from 'moment';
import IconButton from 'components/IconButton/IconButton';
import Select from 'components/Select/Select';
import DateRange from './DateRange';
import { SelectDateRangeOptions } from './Types';

interface props {
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
  options: SelectDateRangeOptions[];
}

function SelectWithDateRange({ label, value, onChange, options }: props) {
  React.useEffect(() => {
    const isUsingDeprecatedOptions = options.some((option) => option.days !== undefined);
    if (isUsingDeprecatedOptions) {
      // eslint-disable-next-line
      if (
        typeof process !== 'undefined' &&
        (process.env?.REACT_APP_ENV === 'integration' || process.env?.REACT_APP_ENV === 'testing' || process.env?.REACT_APP_ENV === 'staging')
      ) {
        // eslint-disable-next-line
        console.warn(
          'Warning: the SelectWithDateRangeFilter has been updated to comply with the standard OptionType. The `days` field is now deprecated and will be removed in the next major version. The `order` field is supported with the newer implementation.'
        );
      }
    }
  }, []);

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const selectEl = useRef(null);
  const [selectValue, fromValue, toValue] = value?.split(':') || [];

  const from: string = fromValue === 'all' ? t('All') : moment(fromValue).format('MM/DD/YYYY');
  const to: string = toValue === 'all' ? t('All') : moment(toValue).format('MM/DD/YYYY');

  const calcDates = (option: SelectDateRangeOptions) => {
    // DEPRECATED
    if (option.days === 'all') {
      onChange(`${option.value}:all:all`);
      return;
    }

    // REPLACES DEPRECATED option.days
    if (option.value === 'all') {
      onChange(`${option.value}::`);
      return;
    }

    // DEPRECATED
    if (option.days === 0) {
      onChange(`${option.value}:${fromValue}:${toValue}`);
      return;
    }

    // REPLACES DEPRECATED option.days
    if (option.value === '0') {
      onChange(`${option.value}:${fromValue}:${toValue}`);
      return;
    }

    const today = new Date();
    const targetDate = new Date();
    const day = (typeof option.days === 'number' ? option.days : Number(option.value)) || 0;

    targetDate.setDate(today.getDate() + day);

    const fromDate = moment(day < 0 ? targetDate : today)
      .format('YYYYMMDD')
      .toString();
    const toDate = moment(day < 0 ? today : targetDate)
      .format('YYYYMMDD')
      .toString();

    onChange(`${option.value}:${fromDate}:${toDate}`);
  };

  useEffect(() => {
    const option = options.filter((i) => i.value === selectValue).pop();
    if (option) calcDates(option);
  }, []);

  const shouldRenderIconButton = options.filter((item) => item.value === selectValue).pop()?.days === 0 || selectValue === '0';

  return (
    <>
      <div
        ref={selectEl}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Select
          id="createdBy"
          value={selectValue}
          label={label}
          placeholder="Select Unit"
          fullWidth
          // onClick={}
          onChange={(e) => {
            const val = e.target.value as string;
            const option = options.filter((item) => item.value === val).pop();
            if (!option) return;
            calcDates(option);
          }}
          options={options}
          required
          inputProps={{
            'aria-label': 'createBy',
          }}
          data-testid="createBy"
          // error={!!formState.errors.unit}
          helperText={from && to ? `From: ${from} To: ${to}` : ' '}
        />
        {shouldRenderIconButton && (
          <IconButton aria-label="calendar" onClick={() => setOpen(true)} fontSize="small">
            <CalendarTodayOutlined fontSize="small" />
          </IconButton>
        )}
      </div>
      <DateRange
        value={fromValue && toValue ? { from: fromValue, to: toValue } : undefined}
        open={open}
        onClose={() => setOpen(false)}
        onChange={(val) => {
          if (!val || !fromValue || !toValue) return;
          onChange(`${selectValue}:${val.from}:${val.to}`);
        }}
        anchorEl={selectEl.current}
      />
    </>
  );
}

export default SelectWithDateRange;
