import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserRoleDisplayStyles } from '../../styles/useUserRoleDisplayStyles';
import { UserRoleDisplayProps } from '../../types/UserRoleDisplayProps';
import { USER_ROLE_LABEL } from './text';

export const UserRoleDisplay = (props: UserRoleDisplayProps) => {
  const { roleName } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const classes = useUserRoleDisplayStyles();

  if (isSmScreen) {
    return (
      <p className={classes.text}>
        <b>{roleName}</b>
      </p>
    );
  }

  return (
    <p className={classes.text}>
      {t(USER_ROLE_LABEL)}
      {' '}
      <b>{roleName}</b>
    </p>
  );
};
