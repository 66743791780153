import { maestroSharedThemeOptions } from "./shared";

const maestroLightThemeDefaultColors = {
  ...maestroSharedThemeOptions.palette.default,
  tint: 'rgb(242, 252, 239)',
  light: 'rgb(122, 217, 95)',
  main: 'rgb(102, 175, 78)',
  dark: 'rgb(77, 150, 53)',
  contrastText: 'rgb(255, 255, 255)',
};

export const maestroLightThemeOptions = {
  ...maestroSharedThemeOptions,
  palette: {
    ...maestroSharedThemeOptions.palette,
    type: 'light',
    default: maestroLightThemeDefaultColors,
    primary: maestroLightThemeDefaultColors,
    success: {
      ...maestroSharedThemeOptions.palette.success,
      light: 'rgb(122, 217, 95)',
      main: 'rgb(102, 175, 78)',
      dark: 'rgb(77, 150, 53)',
      contrastText: 'rgb(255,255,255)',
    },
  } as const,
} as const;
