import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useSubtitleStyles = makeStyles((theme: Theme) => {
  const SUBTITLE_FONT_COLOR = '#999999';
  const SUBTITLE_FONT_SIZE = '0.85em';

  return {
    subtitle: {
      color: SUBTITLE_FONT_COLOR,
      fontSize: SUBTITLE_FONT_SIZE,
    },
  };
});