import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { useAltHeaderStyles } from './styles';
import type { AltHeaderProps } from './types';
import { AltHeaderItem } from './components';

/**
 * A flexible alternative header primarily intended for use within
 * SalesForce-Canvas-embedded apps.
 */
export const AltHeader = (props: AltHeaderProps) => {
  const {
    items,
    className,
    style,
  } = props;
  const classes = useAltHeaderStyles();

  const children = items.map((elem, idx) => {
    const noDivider = idx === items.length - 1;
    return (
      <AltHeaderItem noDivider={noDivider}>
        {elem}
      </AltHeaderItem>
    );
  });

  return (
    <Paper>
      <Grid container className={clsx(classes.root, className)} style={style}>
        {children}
      </Grid>
    </Paper>
  );
};
