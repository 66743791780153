import type { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import React from 'react';
import Select from 'components/Select/Select';

export const LanguageList = [
  { value: 'en_US', label: 'English (Americas)' },
  { value: 'en_CA', label: 'English (Canada)' },
  { value: 'en_UK', label: 'English (UK)' },
  { value: 'fr_CA', label: 'Français (Canada)' },
  { value: 'fr_FR', label: 'Français (France)' },
  { value: 'es_ES', label: 'Español (Spain)' },
  { value: 'es_419', label: 'Español (Latin America)' },
  { value: 'es_MX', label: 'Español (Mexico)' },
  { value: 'es_AR', label: 'Español (Argentina)' },
];

export const Languages = LanguageList.map((item) => item.value);
type ElementType < T extends ReadonlyArray < unknown > > = T extends ReadonlyArray<
infer ElementType
>
  ? ElementType
  : never;

interface SelectProps extends Omit<MuiSelectProps, 'variant'> {
  variant?: 'default' | 'standard' | 'outlined' | 'filled',
  disabled?: boolean,
  helperText?: string,
  label?: string,
  options?: Select.Option[],
  className?: string,
  children?: React.ReactNode,
}
export interface LanguageSelectProps extends SelectProps {
  allowedLanguages?: ElementType<typeof Languages>|ElementType<typeof Languages>[],
  additionalLanguages?: { value: string, label: string }[],
}

export default function LanguageSelect(props: LanguageSelectProps) {
  const { allowedLanguages = Languages, additionalLanguages = [], ...otherProps } = props;
  const languages = LanguageList.filter(
    (language) => allowedLanguages.includes(language.value),
  ).concat(additionalLanguages.filter(
    (item) => !LanguageList.find((language) => language.value === item.value),
  ));

  return (
    <Select {...otherProps} options={languages} />
  );
}

LanguageSelect.defaultProps = {
  allowedLanguages: Languages,
  additionalLanguages: [],
};
