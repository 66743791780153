import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { SelectProps as MuiSelectProps } from '@material-ui/core/Select';

import {
  FormControl as MuiFormControl, InputLabel as MuiInputLabel, FormHelperText as MuiFormHelperText, Select as MuiSelect, MenuItem as MuiMenuItem,
} from '@material-ui/core';
import { ThemeType } from 'types/ThemeType';
import { OptionType } from 'types/Types';

declare namespace Select {
  export type Option = OptionType;
}

export interface SelectProps extends Omit<MuiSelectProps, 'variant'> {
  variant?: 'default' | 'standard' | 'outlined' | 'filled',
  disabled?: boolean,
  helperText?: string,
  label?: string,
  options?: Select.Option[],
  className?: string,
  children?: React.ReactNode,
}

const defaultStyles = makeStyles(() => ({
  label: (props: any) => ({
    top: (props.value || props.focused) ? '4px' : '-4px',
    left: '6px',
  }),
  select: {
    paddingLeft: '6px',
    paddingBottom: '4px',
  },
  helperText: {
    marginLeft: '6px',
    marginTop: '2px',
  },
}));

const formControlStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
}));

const labelStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
  },
}));

const selectStyles = makeStyles((theme: ThemeType) => ({
  disabled: (props: any) => ({
    color: props.readOnly && theme.palette.text.primary,
  }),
  icon: (props: SelectProps) => ({
    display: props.disabled || props.readOnly ? 'none' : 'inherit',
  }),
}));

// eslint-disable-next-line no-redeclare
function Select(props: SelectProps) {
  const [focused, setFocused] = React.useState(false);

  const fullProps = { ...props, focused };

  const defaultStyle = defaultStyles(fullProps);
  const formControlStyle = formControlStyles(fullProps);
  const labelStyle = labelStyles(fullProps);
  const selectStyle = selectStyles(fullProps);

  const defaultLabelStyle = {
    root: defaultStyle.label,
  };

  const defaultSelectStyle = {
    root: defaultStyle.select,
    disabled: selectStyle.disabled,
    icon: selectStyle.icon,
  };

  const defaultHelperTextStyle = {
    root: defaultStyle.helperText,
  };

  const {
    helperText, margin, options, children, ...selectProps
  } = props;
  const {
    className, variant = 'standard', label, onChange, ...otherProps
  } = selectProps;
  const {
    style, required, fullWidth, error, color, classes,
  } = otherProps;
  const formProps = {
    style, required, fullWidth, error, color, classes,
  };
  const disabled = props.disabled || props.readOnly;

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const handleChange = (event, child) => {
    if (onChange) {
      onChange({ ...event, target: { ...event.target, id: props.id } }, child);
    }
  };

  if (variant === 'default') {
    return (
      <MuiFormControl
        {...formProps}
        margin={margin}
        variant="outlined"
        classes={{ root: clsx(className, formControlStyle.root) }}
        disabled={disabled}
      >
        <MuiInputLabel
          variant="standard"
          classes={defaultLabelStyle}
        >
          {label}
        </MuiInputLabel>
        <MuiSelect
          classes={defaultSelectStyle}
          {...selectProps}
          variant="outlined"
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          onChange={handleChange}
        >
          {(options && options.length > 0)
            ? options.map((option) => (
              <MuiMenuItem key={option.value} value={option.value} dense disabled={option.disabled}>{option.label}</MuiMenuItem>
            ))
            : children}
        </MuiSelect>
        <MuiFormHelperText classes={defaultHelperTextStyle}>{helperText}</MuiFormHelperText>
      </MuiFormControl>
    );
  }
  return (
    <MuiFormControl
      {...formProps}
      margin={margin}
      variant={variant}
      classes={{ root: clsx(className, formControlStyle.root) }}
      disabled={disabled}
    >
      {label && <MuiInputLabel
        variant={variant}
        classes={labelStyle}
      >
        {label}
      </MuiInputLabel>}
      <MuiSelect
        classes={selectStyle}
        {...selectProps}
        variant={variant}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        onChange={handleChange}
      >
        {(options && options.length > 0)
          ? options.map((option) => (
            <MuiMenuItem key={option.value} value={option.value} dense disabled={option.disabled}>{option.label}</MuiMenuItem>
          ))
          : children}
      </MuiSelect>
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
}

Select.defaultProps = {
  variant: 'standard',
};

export default Select;
