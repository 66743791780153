import type { ThemeType } from '../../types/ThemeType';
import type { AcidThemeOptions } from './types/AcidThemeOptions';
import { buildThemeLogos } from './utils/buildThemeLogos/buildThemeLogos';
import { buildThemeOptions } from './utils/buildThemeOptions';
import { createBaseTheme } from './utils/createBaseTheme';

export const createTheme = (options: AcidThemeOptions = {}): ThemeType => {
  const themeOptions = buildThemeOptions(options);
  const baseTheme = createBaseTheme(themeOptions);
  const themeLogoDefinition = buildThemeLogos(options.palette?.type ?? 'light');

  const theme: ThemeType = {
    ...baseTheme,
    logo: themeLogoDefinition,
    // we have historically, and continue to here, trusted that createMuiTheme
    // keeps the additionally defined palette keys in our extended options
  } as ThemeType;

  return theme;
};
