import { makeStyles } from '@material-ui/core/styles';

const useSwitchAccountDisplayStyles = makeStyles((theme) => {
  return {
    root: {
      float: 'right',
      paddingRight: theme.spacing(1),
      display: 'flex',
    },
  };
});

export {
  useSwitchAccountDisplayStyles,
};
