const ordinalSuffixOf = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const checkMask = (
  str: string,
  mask?: string,
  max?: number | undefined,
  name = 'number',
  alternative = false,
) => {
  if (!str) return { isOk: true, message: [''], lastOk: 0 };
  let isOk = true;
  let message = '';
  let lastOk = 0;

  if (!mask) {
    if (max && str.length > max) message = `Must not exceed ${max} characters.`;
    if (str.length < 5) message = 'Must be larger than 5 characters.';
    return {
      isOk: !message, message: [message], severity: !message ? undefined : 'error', lastOk,
    };
  }

  const charsQty = mask[mask.length - 1] === ']' ? mask.length - 1 : mask.length;
  const maxQty = mask[mask.length - 1] === ']' ? mask.length - 1 : max;
  const maxSett = mask[mask.length - 1] === ']';

  for (let i = 0; i < str.length; i += 1) {
    if (i === charsQty) break;

    if (!['@', '#', '&', '*'].includes(mask[i]) && str[i] !== mask[i]) {
      isOk = false;
      message = alternative ? `or "${mask[i]}".`
        : `${ordinalSuffixOf(i + 1)} character must be "${mask[i]}"`;
    } // is different

    switch (mask[i]) {
      case '@':
        if (!str[i].match(/^[A-Za-z]$/)) {
          isOk = false;
          message = alternative ? 'or a letter'
            : `${ordinalSuffixOf(i + 1)} character must be a letter`;
        } // must be an alphabetic character
        break;
      case '#':
        if (!str[i].match(/^[0-9]$/)) {
          isOk = false;
          message = alternative ? 'or a number'
            : `${ordinalSuffixOf(i + 1)} character must be a number`;
        } // must be a numeric character
        break;
      case '&':
        if (!str[i].match(/^[0-9A-Za-z]$/)) {
          isOk = false;
          message = alternative ? 'or a number'
            : `${ordinalSuffixOf(i + 1)} character must be a letter or a number`;
        } // must be an alphabetic or a numeric character
        break;
      case '*':
        if (!str[i].match(/[^ ]$/)) {
          isOk = false;
          message = alternative ? 'or not be a space'
            : `${ordinalSuffixOf(i + 1)} char can not be a space`;
        } // must be any character except for a space
        break;
      default:
        break;
    }
    if (isOk) lastOk += 1;
  }

  if (maxQty && str.length > maxQty) {
    return {
      isOk: false,
      message: [`Must not exceed ${maxQty} characters.`],
      severity: 'error',
      lastOk,
    };
  }// is larger

  if (maxSett && ((!maxQty || str.length < maxQty) && isOk)) {
    return {
      isOk,
      message: [
        `In order to continue the ${name} must be ${maxQty} characters.`,
      ],
      severity: 'warning',
      lastOk,
    };
  } // is shorter

  return {
    isOk,
    message: [
      message,
    ],
    severity: isOk ? undefined : 'error',
    lastOk,
  };
};

export interface CheckMasksParams {
  str: string,
  mask?: string,
  maskAlt?: string,
  max?: number,
  name?: string
}

export const checkMasks = ({
  str,
  mask,
  maskAlt,
  max,
  name = 'number',
}: CheckMasksParams) => {
  const maxQtyAlt = maskAlt && maskAlt[maskAlt.length - 1] === ']' ? maskAlt.length - 1 : max;
  const maxSettAlt = maskAlt ? maskAlt[maskAlt.length - 1] === ']' : undefined;
  const mskToDisplayAlt = maxSettAlt && maskAlt ? `${maskAlt.slice(0, -1)} and must not exceed ${maxQtyAlt} characters` : maskAlt;

  const maxQty = mask && mask[mask.length - 1] === ']' ? mask.length - 1 : max;
  const maxSett = mask ? mask[mask.length - 1] === ']' : undefined;
  const mskToDisplay = maxSett && mask ? `${mask.slice(0, -1)} and must not exceed ${maxQty} characters` : mask;

  const maskResult = checkMask(str, mask, max, name);
  const altMaskResult = maskAlt ? checkMask(str, maskAlt, max, name) : null;
  const altOrMaskResult = checkMask(str, maskAlt, max, name, true);
  let message = '';

  if (maskResult?.isOk) return maskResult;
  if (altMaskResult?.isOk) return altMaskResult;
  if (mask && maskAlt && maskResult && altMaskResult && !maskResult?.isOk && !altMaskResult?.isOk) {
    if (maskResult.lastOk === altMaskResult.lastOk) message = `${maskResult.message[0]} ${altOrMaskResult.message[0]}`;
    else if (maskResult.lastOk >= altMaskResult.lastOk) [message] = maskResult.message;
    else [message] = altMaskResult.message;
    if (maskResult.lastOk === 0 && altMaskResult.lastOk === 0 && str.length > 1) message = ' ';

    return ({
      ...maskResult,
      message: [
        message,
        `The ${name} must follow one of these formats:`,
        `  ● ${mskToDisplay}.`,
        `  ● ${mskToDisplayAlt}.`,
      ],
    });
  }
  if (mask && maskResult && !maskResult?.isOk) {
    return ({
      ...maskResult,
      message: [
        ...maskResult.message,
        `The ${name} must follow this format:`,
        `  ● ${mskToDisplay}.`,
      ],
    });
  }
  return maskResult;
};
