import BRAND_CENTER from "./brandCenterConstants";
import { NON_BRAND_COLORS } from "./nonBrandColors";

type SupportedMichelinBrandThemeType = 'light';

const TONAL_OFFSET = 0;
const CONTRAST_THRESHOLD_FOR_ACCESSIBILITY = 4.5;

const GRAY_SCALARS = {
  LIGHT: {
    PRIMARY: 10,
    HELPER: 40,
    DISABLED: 70,
  },
  DARK: {
    PRIMARY: 85,
    HELPER: 55,
    DISABLED: 25,
  },
} as const;

const createMichelinBrandTypography = () => {
  const fontFamily = BRAND_CENTER.typefaces.body.default;

  return {
    fontFamily,
    fontSize: 14,
  };
};

const createTextColors = (type: SupportedMichelinBrandThemeType) => {
  const { text } = BRAND_CENTER.colors;
  const scalars = GRAY_SCALARS[type.toLocaleUpperCase() as 'LIGHT'];

  return {
    primary: text.gray(scalars.PRIMARY),
    hint: text.gray(scalars.HELPER),
    disabled: text.gray(scalars.DISABLED),
  };
};

const createBackgroundColors = (type: SupportedMichelinBrandThemeType) => {
  const { white } = BRAND_CENTER.colors.primary;
  const backgroundColors = {
    default: type === 'light' ? white(95) : white(12),
    paper: type === 'light' ? white() : white(7),
  };

  return backgroundColors;
};

const createFullyPopulatedPaletteColor = (color: string) => {
  return {
    tint: color,
    light: color,
    main: color,
    dark: color,
  };
};

const createMichelinBrandPalette = (type: SupportedMichelinBrandThemeType) => {
  const { colors } = BRAND_CENTER;
  const textColors = createTextColors(type);
  const primaryColor = createFullyPopulatedPaletteColor(colors.primary.michelinBlue);
  const secondaryColor = createFullyPopulatedPaletteColor(colors.primary.white(type === 'light' ? 90 : 10));

  return {
    primary: primaryColor,
    secondary: secondaryColor,
    error: createFullyPopulatedPaletteColor(NON_BRAND_COLORS.ERROR_RED),
    warning: createFullyPopulatedPaletteColor(colors.primary.michelinYellow),
    info: createFullyPopulatedPaletteColor(colors.primary.michelinBlue),
    success: createFullyPopulatedPaletteColor(colors.secondary.generousGreen),
    type,
    tonalOffset: TONAL_OFFSET,
    contrastThreshold: CONTRAST_THRESHOLD_FOR_ACCESSIBILITY,
    common: {
      black: colors.primary.black,
      white: colors.primary.white(100),
    },
    text: textColors,
    background: createBackgroundColors(type),
    default: primaryColor,
    none: secondaryColor,
    alternative: createFullyPopulatedPaletteColor(colors.secondary.purpleEngaged),
  };
};

const createMichelinBrandThemeOptions = (type: SupportedMichelinBrandThemeType) => {
  const typography = createMichelinBrandTypography();
  const palette = createMichelinBrandPalette(type);

  return {
    typography,
    palette,
  };
};

export {
  createMichelinBrandThemeOptions,
};
