export const states = [
  // US
  { label: 'Alabama', value: 'AL', country: 'USA' },
  { label: 'Alaska', value: 'AK', country: 'USA' },
  { label: 'Arizona', value: 'AZ', country: 'USA' },
  { label: 'Arkansas', value: 'AR', country: 'USA' },
  { label: 'California', value: 'CA', country: 'USA' },
  { label: 'Colorado', value: 'CO', country: 'USA' },
  { label: 'Connecticut', value: 'CT', country: 'USA' },
  { label: 'Delaware', value: 'DE', country: 'USA' },
  { label: 'Florida', value: 'FL', country: 'USA' },
  { label: 'Georgia', value: 'GA', country: 'USA' },
  { label: 'Hawaii', value: 'HI', country: 'USA' },
  { label: 'Idaho', value: 'ID', country: 'USA' },
  { label: 'Illinois', value: 'IL', country: 'USA' },
  { label: 'Indiana', value: 'IN', country: 'USA' },
  { label: 'Iowa', value: 'IA', country: 'USA' },
  { label: 'Kansas', value: 'KS', country: 'USA' },
  { label: 'Kentucky', value: 'KY', country: 'USA' },
  { label: 'Louisiana', value: 'LA', country: 'USA' },
  { label: 'Maine', value: 'ME', country: 'USA' },
  { label: 'Maryland', value: 'MD', country: 'USA' },
  { label: 'Massachusetts', value: 'MA', country: 'USA' },
  { label: 'Michigan', value: 'MI', country: 'USA' },
  { label: 'Minnesota', value: 'MN', country: 'USA' },
  { label: 'Mississippi', value: 'MS', country: 'USA' },
  { label: 'Missouri', value: 'MO', country: 'USA' },
  { label: 'Montana', value: 'MT', country: 'USA' },
  { label: 'Nebraska', value: 'NE', country: 'USA' },
  { label: 'Nevada', value: 'NV', country: 'USA' },
  { label: 'New Hampshire', value: 'NH', country: 'USA' },
  { label: 'New Jersey', value: 'NJ', country: 'USA' },
  { label: 'New Mexico', value: 'NM', country: 'USA' },
  { label: 'New York', value: 'NY', country: 'USA' },
  { label: 'North Carolina', value: 'NC', country: 'USA' },
  { label: 'North Dakota', value: 'ND', country: 'USA' },
  { label: 'Ohio', value: 'OH', country: 'USA' },
  { label: 'Oklahoma', value: 'OK', country: 'USA' },
  { label: 'Oregon', value: 'OR', country: 'USA' },
  { label: 'Pennsylvania', value: 'PA', country: 'USA' },
  { label: 'Rhode Island', value: 'RI', country: 'USA' },
  { label: 'South Carolina', value: 'SC', country: 'USA' },
  { label: 'South Dakota', value: 'SD', country: 'USA' },
  { label: 'Tennessee', value: 'TN', country: 'USA' },
  { label: 'Texas', value: 'TX', country: 'USA' },
  { label: 'Utah', value: 'UT', country: 'USA' },
  { label: 'Vermont', value: 'VT', country: 'USA' },
  { label: 'Virginia', value: 'VA', country: 'USA' },
  { label: 'Washington', value: 'WA', country: 'USA' },
  { label: 'West Virginia', value: 'WV', country: 'USA' },
  { label: 'Wisconsin', value: 'WI', country: 'USA' },
  { label: 'Wyoming', value: 'WY', country: 'USA' },

  // Canada
  { label: 'Alberta', value: 'AB', country: 'CAN' },
  { label: 'British Columbia', value: 'BC', country: 'CAN' },
  { label: 'Manitoba', value: 'MB', country: 'CAN' },
  { label: 'New Brunswick', value: 'NB', country: 'CAN' },
  { label: 'Newfoundland and Labrador', value: 'NL', country: 'CAN' },
  { label: 'Nova Scotia', value: 'NS', country: 'CAN' },
  { label: 'Ontario', value: 'ON', country: 'CAN' },
  { label: 'Prince Edward Island', value: 'PE', country: 'CAN' },
  { label: 'Quebec', value: 'QC', country: 'CAN' },
  { label: 'Saskatchewan', value: 'SK', country: 'CAN' },
  { label: 'Northwest Territories', value: 'NT', country: 'CAN' },
  { label: 'Nunavut', value: 'NU', country: 'CAN' },
  { label: 'Yukon', value: 'YT', country: 'CAN' },

  // Mexico
  { label: 'Aguascalientes', value: 'AG', country: 'MEX' },
  { label: 'Baja California Norte', value: 'BC', country: 'MEX' },
  { label: 'Baja California Sur', value: 'BS', country: 'MEX' },
  { label: 'Chihuahua', value: 'CH', country: 'MEX' },
  { label: 'Colima', value: 'CL', country: 'MEX' },
  { label: 'Campeche', value: 'CM', country: 'MEX' },
  { label: 'Coahuila', value: 'CO', country: 'MEX' },
  { label: 'Chiapas', value: 'CS', country: 'MEX' },
  { label: 'Distrito Federal', value: 'DF', country: 'MEX' },
  { label: 'Durango', value: 'DG', country: 'MEX' },
  { label: 'Guerrero', value: 'GR', country: 'MEX' },
  { label: 'Guanajuato', value: 'GT', country: 'MEX' },
  { label: 'Hidalgo', value: 'HG', country: 'MEX' },
  { label: 'Jalisco', value: 'JA', country: 'MEX' },
  { label: 'Michoacan', value: 'MI', country: 'MEX' },
  { label: 'Morelos', value: 'MO', country: 'MEX' },
  { label: 'Nayarit', value: 'NA', country: 'MEX' },
  { label: 'Nuevo Leon', value: 'NL', country: 'MEX' },
  { label: 'Oaxaca', value: 'OA', country: 'MEX' },
  { label: 'Puebla', value: 'PU', country: 'MEX' },
  { label: 'Quintana Roo', value: 'QR', country: 'MEX' },
  { label: 'Queretaro', value: 'QT', country: 'MEX' },
  { label: 'Sinaloa', value: 'SI', country: 'MEX' },
  { label: 'San Luis Potosi', value: 'SL', country: 'MEX' },
  { label: 'Sonora', value: 'SO', country: 'MEX' },
  { label: 'Tabasco', value: 'TB', country: 'MEX' },
  { label: 'Tlaxcala', value: 'TL', country: 'MEX' },
  { label: 'Tamaulipas', value: 'TM', country: 'MEX' },
  { label: 'Veracruz', value: 'VE', country: 'MEX' },
  { label: 'Yucatan', value: 'YU', country: 'MEX' },
  { label: 'Zacatecas', value: 'ZA', country: 'MEX' },
  { label: 'México', value: 'EM', country: 'MEX' },
];
