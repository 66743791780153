/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Paper } from '@material-ui/core';
import {
  Close as CloseIcon, Error as ErrorIcon, CheckCircleOutlineRounded as SuccessIcon, Warning as WarningIcon, Info as InfoIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import IconButton from 'components/IconButton/IconButton';

export type SnackbarNotificationType = 'success' | 'error' | 'warning' | 'info';

export interface SnackbarNotificationProps {
  className?: string;
  title: string;
  message: string | JSX.Element | JSX.Element[];
  type: SnackbarNotificationType;
  handleClose?: () => void;
}

export interface UseSnackbarNotificationStylesProps {
  spacing: number;
}

const useStyles = makeStyles<ThemeType, UseSnackbarNotificationStylesProps>((theme) => ({
  root: (props) => ({
    padding: theme.spacing(props.spacing),
    marginBottom: theme.spacing(1),
  }),
  spacing: {
    marginRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    fontSize: '.8rem',
    alignItems: 'center',
  },
  timestamp: {
    flexGrow: 1,
    textAlign: 'right',
    color: theme.palette.text.disabled,
    lineHeight: 'normal',
  },
  action: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  close: {
    cursor: 'pointer',
  },
  display: {
    padding: theme.spacing(0, 1, 1, 1),
    fontSize: 11,
  },
  title: {
    padding: theme.spacing(1, 1, 1, 1),
    paddingTop: 6,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 12,
  },
  externalAction: {
    paddingRight: theme.spacing(1),
    '& > a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}));

const badgeStyles = makeStyles((theme:ThemeType) => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}));

const getIconByFeedbackType = (type: SnackbarNotificationType) => {
  switch (type) {
    case 'success':
      return <SuccessIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'info':
    default:
      return <InfoIcon />;
  }
};

export const SnackbarNotification = (props: SnackbarNotificationProps) => {
  const {
    className,
    title,
    message,
    type,
    handleClose,
  } = props;
  const classes = useStyles({
    spacing: 1,
  });
  const [showDetail, setShowDetail] = useState(false);
  const badge = badgeStyles(props);

  const icon = getIconByFeedbackType(type);

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <Paper role="notificationPanel" className={clsx(className, classes.root)}>
      <Box display="grid" gridTemplateColumns="40px 1fr">
        <Box p={1} display="grid" alignItems="center" className={badge[type]}>
          {icon}
        </Box>
        <Box pt={1} display="grid" gridTemplateColumns="1fr auto">
          <Box onClick={() => setShowDetail((p) => !p)} style={{ cursor: 'pointer' }} pr={2}>
            <div className={classes.title}>
              <strong>
                {title}
              </strong>
            </div>
            {showDetail && (
              <div
                className={clsx(classes.display)}
              >
                {message}
              </div>
            )}
          </Box>
          {handleClose && (
            <Box>
              <IconButton size="small" fontSize="small" color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
