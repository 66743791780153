import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';

interface PanelGroupProps {
  className?: string,
  children?: React.ReactNode,
  stacked?: boolean,
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: (props: PanelGroupProps) => {
    const { stacked = false } = props;
    return ({
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: stacked ? 'column' : 'row',

      '&>div': {
        flex: '1 1 0%',
        margin: '0px',
        marginRight: stacked ? 'inherit' : '-1px',
        marginBottom: stacked ? '-1px' : 'inherit',
      },
    });
  },
}));

export default function PanelGroup(props) {
  const { className, children } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
}

PanelGroup.defaultProps = {
  stacked: false,
};
