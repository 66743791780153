import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useSelectDateTimeStyles = makeStyles((theme: Theme) => {
  return {
    selectDateTimeContainer: {
      minWidth: 700,
      '& div.MuiPickersStaticWrapper-staticWrapperRoot': {
        backgroundColor: 'transparent',
      },
    },
    datePicker: {
      '& button.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton': {
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    hourButton: {
      width: 80,
      height: 20,
      margin: theme.spacing(.5),
      boxShadow: 'none',
    },
    appointmentContainer: {
      margin: 16,
    },
    appointmentPanel: {
      height: 55,
      margin: '0 auto',
      textAlign: 'center',
      backgroundColor: 'transparent',
      border: 'none',

      '&>div': {
        backgroundColor: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    ampmColumn: {
      textAlign: 'center',
    },
    ampmTitle: {
      margin: '0 auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    appointment: {
      fontSize: 16,
    },
    appointmentIcon: {
      marginRight: theme.spacing(1),
      marginBottom: -6,
      color: theme.palette.primary.dark,
    },
    appointmentText: {
      '&>div': {
        display: 'inline-block',
      }
    }
  };
});