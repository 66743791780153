import React from 'react';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/IconButton/IconButton';

export function GoHomeBtn() {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <IconButton
      style={{
        height: 35, width: 35, padding: 8, marginTop: -5,
      }}
      aria-label={t('Back to Main Menu')}
      title={t('Back to Main Menu')}
      onClick={() => {
        let btNumber = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const parts of window.location.pathname.split('/')) {
          const candidate = parts.trim();
          if (!candidate) continue;
          if (candidate.length !== 7) continue;
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(candidate as any)) continue;
          btNumber = candidate;
          break;
        }
        history.push(`/${btNumber}`);
      }}
    >
      <ArrowBackIos style={{ height: 18, marginLeft: 6 }} color="primary" />
    </IconButton>
  );
}
