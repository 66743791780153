/* eslint-disable react/no-array-index-key */
import { Box } from '@material-ui/core';
import React from 'react';
import { TitleItemType } from './types';

export interface IPageTitleAddonProps {
  items: TitleItemType | undefined;
  visible?: boolean;
  className?: string;
}

export const PageTitleAddon = (props: IPageTitleAddonProps) => {
  const {
    items,
    className,
    visible = true,
  } = props;

  if (!items || !visible) return null;

  return (
    <Box
      component="span"
      className={className || ''}
    >
      { Array.isArray(items)
        ? items.map((item, i) => <React.Fragment key={`item_${i}`}>{item}</React.Fragment>)
        : items }
    </Box>
  );
};
