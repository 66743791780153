/**
 * these are the colors of the Michelin Design System as described
 * at https://designsystem.michelin.com/brand/colors
 */

const MICHELIN_DESIGN_SYSTEM_COLORS = {
  LIGHT: {
    PRIMARY_WHITE: '#FFFFFF',
    PRIMARY_BLUE: '#27509B',
    ACCENT_PURPLE: '#7F00E0',
    GREY_SECONDARY: '#DCDDDE',
    ERROR_RED: '#CC0033',
    WARNING_ORANGE: '#FF9520',
    INFO_BLUE: '#4271D6',
    SUCCESS_GREEN: '#0C8A0A',
    TEXT_NORMAL: 'rgba(0, 0, 0, 0.87)',
    TEXT_HELPER: 'rgba(0, 0, 0, 0.6)',
    TEXT_DISABLED: 'rgba(0, 0, 0, 0.3)',
    TEXT_ERROR: '#CC0033',
    LAYOUT_WHITE: '#FFFFFF',
    LAYOUT_GREY_1: '#F5F5F5',
    LAYOUT_GREY_2: '#EEEEEE',
    LAYOUT_GREY_3: '#E0E0E0',
    LAYOUT_GREY_4: '#BDBDBD',
    MICHELIN_BLUE: '#27509B',
    MICHELIN_WHITE: '#FFFFFF',
    MICHELIN_YELLOW: '#FCE500',
    MICHELIN_PURPLE: '#582C83',
    MICHELIN_GREEN: '#84BD00',
    MICHELIN_GREY: '#53565A',
  },
  DARK: {
    PRIMARY_WHITE: '#FFFFFF',
    PRIMARY_BLUE: '#99CEF6',
    ACCENT_PURPLE: '#C196F0',
    GREY_SECONDARY: '#444444',
    ERROR_RED: '#CC0033',
    WARNING_ORANGE: '#FF9520',
    INFO_BLUE: '#4271D6',
    SUCCESS_GREEN: '#0C8A0A',
    TEXT_NORMAL: 'rgba(255, 255, 255, 0.87)',
    TEXT_HELPER: 'rgba(255, 255, 255, 0.6)',
    TEXT_DISABLED: 'rgba(255, 255, 255, 0.3)',
    TEXT_ERROR: '#CC0033',
    LAYOUT_WHITE: '#121212',
    LAYOUT_GREY_1: '#1E1E1E',
    LAYOUT_GREY_2: '#272727',
    LAYOUT_GREY_3: '#2E2E2E',
    LAYOUT_GREY_4: '#363636',
    MICHELIN_BLUE: '#27509B',
    MICHELIN_WHITE: '#FFFFFF',
    MICHELIN_YELLOW: '#FCE500',
    MICHELIN_PURPLE: '#582C83',
    MICHELIN_GREEN: '#84BD00',
    MICHELIN_GREY: '#53565A',
  },
} as const;

export {
  MICHELIN_DESIGN_SYSTEM_COLORS,
};
