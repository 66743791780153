import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AltHeader } from '../../AltHeader';
import { AccountDisplay, UserInteractionsDisplay, UserRoleDisplay } from '../../components';
import { AccountDisplayProps, UserInteractionsDisplayProps } from '../../types';

interface LegacyCanvasHeaderProps {
  /**
   * the name of the role assigned to the user
   */
  roleName: string;
  /**
   * the location details for the Bill To account
   */
  billTo: AccountDisplayProps['account'];
  /**
   * the location details for the Ship To account
   */
  shipTo: AccountDisplayProps['account'];
  /**
   * a set of elements to display to the user in the rightmost section of the
   * header -- typically elements which interact with the consuming app's state
   * in some way
   */
  interactions: UserInteractionsDisplayProps['children'];
}

/**
 * An alternative header for use within SalesForce-Canvas-embedded apps intended
 * to replicate the UX of the header previously provided by SalesForce itself.
 * For additional flexibility, utilize the base component: `AltHeader`.
 *
 * @deprecated use the `CanvasHeader` preset or `AltHeader` component directly instead
 */
const LegacyCanvasHeader = (props: LegacyCanvasHeaderProps) => {
  const {
    roleName,
    billTo,
    shipTo,
    interactions,
  } = props;

  const { t } = useTranslation();

  const elements = useMemo(() => [
    <UserRoleDisplay roleName={roleName} />,
    <AccountDisplay
      label={t('Bill To')}
      account={billTo}
    />,
    <AccountDisplay
      label={t('Ship To')}
      account={shipTo}
    />,
    <UserInteractionsDisplay>
      {interactions}
    </UserInteractionsDisplay>,
  ], [roleName, billTo, shipTo, interactions, t]);

  return (
    <AltHeader items={elements} />
  );
};

export {
  LegacyCanvasHeader,
  type LegacyCanvasHeaderProps,
};
