import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar as MuiAppBar } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import { acidForwardRef } from '../../utilities/forwardRef';

const colorStyles = makeStyles((theme: ThemeType) => ({
  default: () => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  }),
  primary: () => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
  secondary: () => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }),
  success: () => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  }),
  info: () => ({
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  }),
  warning: () => ({
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  }),
  danger: () => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }),
}));

export interface TabBarProps extends Omit<MuiAppBarProps, 'color'> {
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
}

/**
Included here are examples on how to use the **TabBar** and it's associated components: **Tabs**, **Tab**, and **TabPanel**.

## Usage

To use the Acid Tabs first created the **TabBar**. Depending on your situation you may want to position the bar into a static position.

The **TabBar** should contain a **Tabs** component which requires both the `value` and the `onChange` properties to be set. The **Tabs** will contain one or more **Tab** components. These will be the individual tab buttons that are displayed in the bar.

Finally, it is best to use the **TabPanel** as it has the correct styling applied to work in conjunction with the **TabBar**. However, there may be situations where a differnt styling is needed.
 */
const TabBar = acidForwardRef<JSX.Element, TabBarProps>((props, ref) => {
  const colors = colorStyles(props);
  const {
    children, className, color = 'default', ...otherProps
  } = props;

  return (
    <MuiAppBar className={clsx(className, colors[color])} {...otherProps} ref={ref as React.RefObject<JSX.Element>}>
      {children}
    </MuiAppBar>
  );
});

export default TabBar;
