import commercialFavicon from '../../assets/images/favicons/favicon-michelin_com_512x512.png';
import corporateFavicon from '../../assets/images/favicons/favicon-michelin_group_512x512.png';

type FaviconType = 'commercial' | 'corporate';

const getFavicon = (type: FaviconType) => {
  switch (type) {
    case 'commercial':
      return commercialFavicon;
    case 'corporate':
      return corporateFavicon;
    default:
      throw new TypeError('Unimplemented favicon');
  }
};

export {
  FaviconType,
  getFavicon,
};
