import type { AcidTheme } from 'themes/ThemeDefinitions';
import { PoweredByLogo } from 'utilities/createTheme/logos/PoweredByLogo';

/**
 * @deprecated prefer Michelin branding
 */
export const getLogoPoweredBy = (type: AcidTheme) => {
  const baseLogoDefinition = {
    light: PoweredByLogo.LIGHT,
    dark: PoweredByLogo.DARK,
  };

  switch (type) {
    case 'dark':
      return {
        ...baseLogoDefinition,
        primary: PoweredByLogo.DARK,
      };
    case 'light':
    default:
      return {
        ...baseLogoDefinition,
        primary: PoweredByLogo.LIGHT,
      };
  }
};
