import React, { useState } from 'react';
import { Button, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import {
  ArrowBackIosOutlined as ArrowBackIosOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  vertical: {
    width: '24px',
    position: 'absolute',
    height: '100%',
    left: '239px',
    zIndex: 1201,
    transition: 'left 225ms cubic-bezier(0.4, 0, 0.6, 1)',
    '&:hover': {
      cursor: 'pointer',
    },
    '&--active': {
      left: '51px',
    },
  },
  verticalShadow: {
    width: '2px',
    height: '100%',
    transition: 'all .3s ease',
  },
  verticalShadowOpen: {
    background: theme.palette.primary.main,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  floatingButton: {
    position: 'absolute',
    padding: '0',
    width: '10px',
    minWidth: '24px',
    height: '24px',
    top: '72px',
    borderRadius: '12px',
    left: '-12px',
    zIndex: 1200,
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    boxShadow: '0px 2px rgb(0 0 0 / 4%)',
    transition: 'left 225ms cubic-bezier(0.4, 0, 0.6, 1)',
    opacity: 0,
    '&--active': {
      background: `${theme.palette.primary.main} !important`,
    },
    '&--show': {
      opacity: 1,
    },
    '&--with-title': {
      top: '14px',
    },
  },
  floatingButtonOpen: {
    left: '-11px',
  },
}));

export interface VerticalFloatingProps {
  open: boolean,
  handler: (open: boolean) => void,
  hasTitle?: boolean,
}

export default function VerticalFloating(props: VerticalFloatingProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, handler, hasTitle } = props;
  const [showShadow, setShowShadow] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const onHandler = () => {
    setShowShadow(false);
    setActiveButton(false);
    handler(!open);
    if (!open) {
      setShowButton(false);
    }
  };

  const onButtonFocus = () => {
    setActiveButton(true);
    setShowShadow(false);
  };

  const onVerticalFocus = () => {
    setShowShadow(true);
    setShowButton(true);
  };

  const onVerticalLeave = () => {
    setShowShadow(false);
    if (open) {
      setShowButton(false);
    }
  };

  return (
    <div
      className={clsx(classes.vertical, {
        [`${classes.vertical}--active`]: !open,
      })}
      onClick={onHandler}
      onMouseEnter={onVerticalFocus}
      onMouseLeave={onVerticalLeave}
    >
      <div
        className={clsx(classes.verticalShadow, {
          [classes.verticalShadowOpen]: showShadow,
        })}
      >
      </div>
      <Button
        className={clsx(classes.floatingButton, {
          [classes.floatingButtonOpen]: open,
          [`${classes.floatingButton}--active`]: activeButton,
          [`${classes.floatingButton}--show`]: showButton,
          [`${classes.floatingButton}--with-title`]: hasTitle,
        })}
        onClick={onHandler}
        onMouseEnter={onButtonFocus}
        onMouseLeave={() => setActiveButton(false)}
      >
        {
          open
            ? <ArrowBackIosOutlinedIcon style={{ fontSize: 11, color: activeButton ? 'white' : theme.palette.text.primary }} />
            : <ArrowForwardIosOutlinedIcon style={{ fontSize: 11, color: activeButton ? 'white' : theme.palette.text.primary }} />
        }
      </Button>
    </div>
  );
}
