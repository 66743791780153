import { AccountSelector } from "components/AccountSelector/AccountSelector";
import { withDialog } from "../withDialog";

const withAccountSelectorDialog = <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
  return withDialog(AccountSelector)(WrappedComponent);
};

export {
  withAccountSelectorDialog,
};
