import React from 'react';
import clsx from 'clsx';
import { useMichelinLogoStyles } from './useMichelinLogoStyles';
import { getMichelinLogoPath } from './getMichelinLogoPath';

export type MichelinLogoDirection = 'stacked' | 'horizontal';

export interface MichelinLogoProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  direction: MichelinLogoDirection;
}

export const MichelinLogo = (props: MichelinLogoProps) => {
  const {
    /* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
    direction,
    className,
    /* eslint-enable @typescript-eslint/no-unused-vars, no-unused-vars */
    ...imgProps
  } = props;
  const classes = useMichelinLogoStyles({ direction });

  return (
    <img
      alt="logo"
      src={getMichelinLogoPath(props)}
      className={clsx(classes.logo, className)}
      {...imgProps}
    />
  );
};
