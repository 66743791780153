import { useMemo, useState } from 'react';
import { useTranslation, type UseTranslationResponse } from 'react-i18next';
import { SelectDateRangeOptions } from 'components/SelectWithDateRange/Types';
import useURLHashParams from './hashParameters';
import { ListingFilterSelectWithDateRange } from './ListingFilterSelectWithDateRange';

export type SetFilteredRowsFunction = (rows: any[]) => void;
const NO_SELECTION_VALUE = '<<ALL-ELEMENTS-SELECTED>>';

interface UseListSelectFilterProps {
  label: string;
  options: SelectDateRangeOptions[];
  unselectedLabel?: string;
  defaultValue?: string;
  hashParam?: string;
}

const buildDeprecatedFullOptions = (t: UseTranslationResponse['t'], unselectedLabel: UseListSelectFilterProps['unselectedLabel'], options: SelectDateRangeOptions[]) => {
  const fullOptions: SelectDateRangeOptions[] = [];

  if (unselectedLabel) fullOptions.push({ label: t(unselectedLabel), value: NO_SELECTION_VALUE, days: null });
  options.forEach((option) => {
    fullOptions.push({ label: t(option.label), value: option.value, days: option.days });
  });
  
  return fullOptions;
};

const buildFullOptions = (t: UseTranslationResponse['t'], unselectedLabel: UseListSelectFilterProps['unselectedLabel'], options: SelectDateRangeOptions[]) => {
  const isUsingDeprecatedOptions = options.some((option) => option.days !== undefined);

  if (isUsingDeprecatedOptions) return buildDeprecatedFullOptions(t, unselectedLabel, options);

  const fullOptions: SelectDateRangeOptions[] = [];
  if (unselectedLabel) fullOptions.push({ label: t(unselectedLabel), value: NO_SELECTION_VALUE, order: '' });
  options.forEach((option) => {
    fullOptions.push({ label: t(option.label), value: option.value, order: option.order });
  });

  return fullOptions;
};

/**
Included here are examples on how to use the **useListSelectFilter** hook.

## Usage

This hook is intended to be used with DevEx DataGrid Tables, and useMaestroDataSource from Central Provider.

See full documentation here:
https://servicesolutions.atlassian.net/wiki/spaces/GSDSIS/pages/2365849655/Maestro+Data+Source
 */
export function useListSelectWithDateRangeFilter({ label, options, unselectedLabel, hashParam, defaultValue }: UseListSelectFilterProps) {

  const { t, i18n } = useTranslation();
  const { getHashValue, setHashValue } = useURLHashParams();
  const [value, setValue] = useState<string | undefined>();

  const filter = useMemo(() => {
    const fullOptions = buildFullOptions(t, unselectedLabel, options);
    const onExecute = (newValue: string | undefined) => {
      setValue(newValue === NO_SELECTION_VALUE ? undefined : newValue);
      return true;
    };
    const f = new ListingFilterSelectWithDateRange({
      label: t(label),
      options: fullOptions,
      defaultValue,
      onExecute,
      hashParam,
      getHashValue,
    });
    if (f.defaultOption) {
      const defaultOptionValue = f.defaultOption.value;
      onExecute(defaultOptionValue);
      if (hashParam) {
        setHashValue(hashParam, defaultValue || defaultOptionValue !== NO_SELECTION_VALUE ? defaultOptionValue : undefined);
      }
    }
    return f;
  }, [label, unselectedLabel, JSON.stringify(options), hashParam, i18n?.language, defaultValue]);

  return {
    filter,
    value,
  };
}
