import { createMuiTheme } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core';
import { DEFAULT_THEME } from 'themes/defaultTheme';
import type { AcidPaletteOptions } from '../types/AcidPaletteOptions';
import type { AcidThemeOptions } from '../types/AcidThemeOptions';
import { buildThemeOptions } from './buildThemeOptions';
import { _createOldMichelinTheme } from './_createOldMichelinTheme';

export const createBaseTheme = (options?: AcidThemeOptions): Theme => {
  const palette = options?.palette ?? {} as AcidPaletteOptions;
  const acidThemeType = palette.type ?? DEFAULT_THEME;

  if (acidThemeType === 'michelin') {
    return _createOldMichelinTheme(options);
  }

  const muiThemeOptions = buildThemeOptions(options);
  const baseTheme = createMuiTheme(muiThemeOptions);

  return baseTheme;
};
