import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  FormControl as MuiFormControl,
  TextareaAutosize,
  TextareaAutosizeProps as MuiTextAreaFieldProps,
  FormHelperText as MuiFormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { OptionType } from 'types/Types';

interface LocalTextAreaFieldProps {
  fullWidth?: boolean,
  placeholder?: string,
  helperText?: string | null | false,
  required?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  value?: OptionType|string,
  error?: boolean,
  className?: string,
}

export type TextAreaFieldProps = LocalTextAreaFieldProps & MuiTextAreaFieldProps;

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    margin: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
  textArea: (props: TextAreaFieldProps) => ({
    width: '100%',
    borderRadius: 0,
    border: `1px solid ${props.error ? theme.palette.error.main : theme.palette.grey[400]}`,
    backgroundColor: theme.palette.type === 'dark' ? 'rgb(85, 85, 85)' : theme.palette.background.paper,
    color: theme.palette.text.primary,
    outlineColor: `${props.error ? theme.palette.error.dark : theme.palette.primary.main}`,
    padding: theme.spacing(0.5),
    '&:hover': {
      border: `1px solid ${props.error ? theme.palette.error.dark : theme.palette.grey[600]}`,
    },
  }),
  readOnly: {
    color: theme.palette.text.primary,
  },
  helperText: {
    color: theme.palette.text.hint,
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
    marginBottom: 0,
    marginRight: theme.spacing(0.5),
  },
  error: (props: any) => ({
    color: theme.palette.error.main,
    opacity: (props.disabled || props.readOnly) && '50%',
  }),
}));

export default function TextAreaField(props: TextAreaFieldProps) {
  const {
    required = false,
    disabled = false,
    fullWidth = true,
    rowsMin = 10,
    rowsMax = 10,
    className,
    name,
    value,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    helperText,
    error,
    ...otherProps
  } = props;

  const readOnly = props.readOnly || false;
  const classes = useStyles(props);

  return (
    <MuiFormControl
        disabled={disabled || readOnly}
        className={clsx(classes.root, props.className, fullWidth && classes.fullWidth)} 
    >
        <TextareaAutosize
            className={clsx(classes.textArea)}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            rowsMin={rowsMin}
            rowsMax={rowsMax}
            placeholder={placeholder}
            disabled={disabled || readOnly}
            {...otherProps}
        />
        {helperText && (
            <MuiFormHelperText
                className={clsx(classes.helperText, classes.error)}
            >
                {helperText}
            </MuiFormHelperText>)
        }
    </MuiFormControl>     
  );
}
