const maestroSharedThemeDefaultColors = {
  tint: 'rgb(242, 252, 249)',
};

export const maestroSharedThemeOptions = {
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 14,
  },
  palette: {
    default: maestroSharedThemeDefaultColors,
    primary: maestroSharedThemeDefaultColors,
    secondary: {
      tint: 'rgb(244, 242, 237)',
      light: 'rgb(224, 222, 208)',
      main: 'rgb(217, 213, 197)',
      dark: 'rgb(191, 189, 167)',
    },
    alternative: {
      tint: 'rgb(244 232 255)',
      light: 'rgb(171 146 194)',
      main: 'rgb(150, 119, 179)',
      dark: 'rgb(105 83 125)',
      contrastText: 'rgb(255,255,255)',
    },
    success: {
      tint: 'rgb(242, 252, 239)',
    },
    info: {
      tint: 'rgb(244 255 255)',
      light: 'rgb(177, 233, 233)',
      main: 'rgb(158, 228, 228)',
      dark: 'rgb(110, 159, 159)',
    },
    warning: {
      tint: 'rgb(255, 251, 241)',
      light: 'rgb(250, 209, 95)',
      main: 'rgb(249, 198, 55)',
      dark: 'rgb(174, 138, 38)',
    },
    error: {
      tint: 'rgb(255, 175, 175)',
      light: 'rgb(243, 111, 111)',
      main: 'rgb(212, 42, 42)',
      dark: 'rgb(198, 15, 15)',
    },
    none: {
      tint: 'rgb(244, 242, 237)',
      light: 'rgb(224, 222, 208)',
      main: 'rgb(217, 213, 197)',
      dark: 'rgb(191, 189, 167)',
    },
  },
};
