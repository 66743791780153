import React from 'react';
import { Grid } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

interface LoadingBounceProps {
  size?: string,
  color?: string,
  margin?: string,
  style?: React.CSSProperties,
}

const bouncedelay = keyframes`
        0%,
        80%,
        100% {
                -webkit-transform: scale(0);
                transform: scale(0);
        }
        40% {
                -webkit-transform: scale(1.0);
                transform: scale(1.0);
        }
    `;

const Spinner = styled.div`
        height: 100%;
        align-items: center;
    display: flex;
    justify-content: center;
    `;

const Bounce = styled.div`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-color: ${(props) => props.color};
    margin: 0 ${(props) => props.margin};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  `;
const Bounce1 = styled(Bounce)`
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  `;
const Bounce2 = styled(Bounce)`
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  `;

export default function LoadingBounce(props: LoadingBounceProps) {
  const {
    size = '1.75vh',
    color = '#7e7e7e',
    margin = '.25rem',
    ...otherProps
  } = props;

  return (
    <Grid container justify="center" style={{ minHeight: '32px' }} {...otherProps}>
      <Grid item xs={12}>
        <Spinner {...props}>
          <Bounce1 size={size} color={color} margin={margin} />
          <Bounce2 size={size} color={color} margin={margin} />
          <Bounce size={size} color={color} margin={margin} />
        </Spinner>
      </Grid>
    </Grid>
  );
}

LoadingBounce.defaultProps = {
  size: '1.75vh',
  color: '#7e7e7e',
  margin: '.25rem',
};
