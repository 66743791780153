import React from 'react';

interface ConditionalRenderProps {
  shouldRender: boolean | (() => boolean);
}

/**
 * indicates that the wrapped props should not include `shouldRender`
 * as it will be masked out by the HoC
 */
type WithConditionalRenderProps<T extends {}> = T extends { shouldRender: unknown } ? never : T & ConditionalRenderProps;

const withConditionalRender = (placeholderElement: JSX.Element | null = null) => {
  return <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
    return (props: WithConditionalRenderProps<WrappedComponentProps>) => {
      const {
        shouldRender,
        ...wrappedComponentProps
      } = props;

      if (!shouldRender) {
        return placeholderElement;
      }

      return (
        // WithConditionalRenderProps protects against improper prop typings but TS doesn't recognize here
        <WrappedComponent {...wrappedComponentProps as unknown as WrappedComponentProps} />
      );
    };
  };
};

export {
  withConditionalRender,
  type WithConditionalRenderProps,
};
