import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tab as MuiTab } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import type { TabProps as MuiTabProps } from '@material-ui/core/Tab';
import { acidForwardRef } from '../../utilities/forwardRef';

export interface TabProps extends MuiTabProps {
  labelPosition?: 'flex-start' | 'center' | 'flex-end',
  required?: boolean,
  error?: boolean,
}

const classStyles = makeStyles((theme: ThemeType) => ({
  selected: () => ({
  }),
  wrapper: (props: TabProps) => ({
    alignItems: props.labelPosition,
    color: props.error ? theme.palette.error.main : undefined,
  }),
}));

const Tab = acidForwardRef<HTMLDivElement, TabProps>((props, ref) => {
  const classes = classStyles(props);
  const {
    className, required, label, ...otherProps
  } = props;
  delete otherProps.labelPosition;

  return (
    <MuiTab
      className={clsx(className)}
      classes={classes}
      label={`${label}${required ? ' *' : ''}`}
      {...otherProps}
      style={{
        ...(otherProps.style || {}),
        fontWeight: 'bold',
      }}
      ref={ref as React.RefObject<HTMLDivElement>}
    />
  );
});

Tab.defaultProps = {
  labelPosition: 'center',
};

export default Tab;
