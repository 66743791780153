import React from 'react';
import { ListingFilter } from './ListingFilter';

export class NoFilter extends ListingFilter {
  constructor() {
    super({ label: '', options: [] });
  }

  render() {
    return <></>;
  }
}
