import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import type { ThemeType } from 'types/ThemeType';
import { useTheme } from '@material-ui/core/styles';

const OPACITY = {
  light: 0.2,
  dark: 0.5,
};

const useStyles = makeStyles((theme: ThemeType) => {
  const THEME_DARK = theme.palette.type === 'dark';
  return {
    statusBadge: (props: any) => {
      const VARIANT_LIGHT = props.variant === 'light';
      return {
        borderRadius: '4px',
        fontWeight: 'bold',
        padding: '2px 8px',
        fontSize: '12px',
        width: 'fit-content',
        textTransform: 'capitalize',
        color: props.textColor,
        '&--pill': {
          borderRadius: '14px',
          padding: '4px 8px',
        },
        '&--primary': {
          backgroundColor: fade('#0d6efd', VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? '#0d6efd' : (props.textColor || '#fff'),
          '&--dark': {
            color: VARIANT_LIGHT ? '#96c0ff' : (props.textColor || '#fff'),
          },
        },
        '&--secondary': {
          backgroundColor: fade('#6c757d', VARIANT_LIGHT ? 0.2 : 1),
          // eslint-disable-next-line no-nested-ternary
          color: VARIANT_LIGHT ? (THEME_DARK ? (props.textColor || '#d1d1d1') : '#6c757d') : (props.textColor || '#fff'),
        },
        '&--success': {
          backgroundColor: fade('#198754', VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? '#198754' : (props.textColor || '#fff'),
          '&--dark': {
            backgroundColor: fade('#198754', VARIANT_LIGHT ? 0.2 : 1),
            color: VARIANT_LIGHT ? '#1cd780' : (props.textColor || '#fff'),
          },
        },
        '&--danger': {
          backgroundColor: fade('#dc3545', VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? '#dc3545' : (props.textColor || '#fff'),
          '&--dark': {
            color: VARIANT_LIGHT ? '#ff808c' : (props.textColor || '#fff'),
          },
        },
        '&--warning': {
          backgroundColor: fade('#ffc107', VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? '#db9900' : (props.textColor || '#fff'),
        },
        '&--info': {
          backgroundColor: fade('#0dcaf0', VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? '#0dcaf0' : (props.textColor || '#fff'),
        },
        '&--override': {
          backgroundColor: props.bgOverride && fade(props.bgOverride, VARIANT_LIGHT ? 0.2 : 1),
          color: VARIANT_LIGHT ? props.bgOverride : (props.textColor || '#fff'),
        },
      };
    },
  };
});

export interface StatusBadgeProps {
  bg: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  bgOverride?: string;
  textColor?: string;
  variant?: 'light' | 'default';
  pill?: boolean;
}

export const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
  const {
    bg,
    bgOverride,
    textColor = '#FFF',
    variant = 'default',
    pill = false,
    children,
  } = props;
  const classes = useStyles({
    variant,
    bgOverride,
    textColor,
  });
  const theme = useTheme();

  return (
    <div
      className={
        clsx(classes.statusBadge,
          {
            [`${classes.statusBadge}--pill`]: pill,
            [`${classes.statusBadge}--override`]: !!bgOverride,
          },
          `${classes.statusBadge}--${bg}`,
          `${classes.statusBadge}--${bg}--${theme.palette.type}`)
      }
    >
      {children}
    </div>
  );
};
