/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/styles';
import { ThemeType } from 'types/ThemeType';

const drawerWidth = 240;
export const baseStyles = makeStyles((theme: ThemeType) => ({
  root: {
    height: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    height: '100%',
    overflowY: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
  },
  drawerClose: {
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
    overflowX: 'hidden',
    width: theme.spacing(7) as number - 3,
  },
  toolbar: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 52,
  }),
  toolbarLabel: (props: any) => ({
    flexGrow: 1,
    display: props.mini && 'none',
  }),
  list: {
    flexGrow: 1,
  },
  itemContainer: {
    height: '40px',
    '&--selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: '#21417d',
      },
    },
  },
  label: {
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&--selected': {
      '& > span': {
        fontWeight: 'bold',
        color: '#FFFFFF',
      },
    },
  },
  icon: {
    '&--selected': {
      color: '#FFFFFF',
      filter: 'brightness(0) invert(1)',
    },
  },
  footer: (props: any) => ({
    position: 'relative',
    bottom: 0,
    width: props.mini
      ? theme.spacing(8)
      : props.drawerWidth < theme.spacing(8)
        ? theme.spacing(8)
        : props.drawerWidth - 1,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: props.mini ? theme.spacing(0, 1) : theme.spacing(1),
    fontSize: 10,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.hover,
  }),
  infoIcon: () => ({
    position: 'relative',
    left: 'calc(50% - 12px)',
    top: 2,
  }),
  heading: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.hover,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  miniHeading: {
    minHeight: 16,
    height: 16,
    backgroundColor: theme.palette.action.hover,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    height: '100%',
  },
  listIconHeading: (props: any) => ({
    display: 'flex',
    alignItems: 'center',
    height: 40,
    textAlign: props.open ? 'right' : 'center',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.hover,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
}));

export const externalStyle = makeStyles((theme: ThemeType) => ({
  heading: (props: any) => ({
    display: 'flex',
    alignItems: 'center',
    height: 40,
    textAlign: props.open ? 'right' : 'center',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.hover,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  headingText: {
    transition:
      'opacity 225ms linear, width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
    '& > span': {
      fontSize: '0.875rem',
    },
  },
  icon: {
    height: 22,
    width: 22,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  externalLinkText: {
    fontSize: '0.8rem',
  },
}));
