import React from 'react';
import { Radio as MuiRadio } from '@material-ui/core';
import { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';

interface RadioProps extends MuiRadioProps {
  color?: 'default'|'primary'|'secondary',
}

export default function Radio(props: RadioProps) {
  const { color = 'primary', ...otherProps } = props;

  return (
    <MuiRadio
      {...otherProps}
      color={color === 'default' ? 'primary' : color}
    />
  );
}
