import { InvalidThemeNameError } from 'errors/InvalidThemeNameError';
import type { AcidTheme } from 'themes/ThemeDefinitions';
import { THEME_DEFINITIONS, ACID_THEMES } from 'themes/ThemeDefinitions';
import cloneDeep from 'lodash/cloneDeep';

const isAcidTheme = (maybeAcidThemeName: unknown): maybeAcidThemeName is AcidTheme => {
  if (typeof maybeAcidThemeName !== 'string') return false;

  return ACID_THEMES.includes(maybeAcidThemeName as AcidTheme);
};

export const getBaseThemeOptions = (type: AcidTheme) => {
  if (!isAcidTheme(type)) throw new InvalidThemeNameError(type);

  return cloneDeep(THEME_DEFINITIONS[type]);
};
