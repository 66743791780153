import MichelinCommercialStackedWhiteBG from '../../../assets/images/logos/Michelin_C_S_WhiteBG_RGB_0621-01.svg';
import MichelinCommercialHorizontalWhiteBG from '../../../assets/images/logos/Michelin_C_H_WhiteBG_RGB_0621-01.svg';

const LOGOS = {
  commercial: {
    stacked: {
      whiteBg: MichelinCommercialStackedWhiteBG,
    },
    horizontal: {
      whiteBg: MichelinCommercialHorizontalWhiteBG,
    },
  },
  // unused at this time
  // group: {
  //   horizontal: {
  //   },
  //   stacked: {
  //   },
  // },
};

export {
  LOGOS,
};
