import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',

    '&>div': {
      flex: '1 1 0%',
      margin: '0px',
      marginRight: '-1px',
    },
  },
}));

interface TextFieldGroupProps {
  className?: string,
  children?: React.ReactNode,
}

export default function TextFieldGroup(props: TextFieldGroupProps) {
  const { className, children } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
}
