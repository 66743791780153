/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Close, Link, Menu } from '@material-ui/icons';
import Tooltip from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { externalStyle } from './styles';
import { DialogProvider, useDialogs } from '../DialogProvider';
import icon from '../../images/logos/bib2.jpg';
import type { ItemType } from './Sidebar';

interface SidebarExternalsProps {
  title?: string,
  items?: ItemType[],
  open: boolean,
  showLinks: boolean;
  setShowLinks: (c: boolean) => void;
  closeSidebar?: () => void;
}

const SidebarExternals = ({
  title = 'Quick Links', items = [], open, showLinks, setShowLinks, closeSidebar = noop,
}: SidebarExternalsProps) => {
  const classes = externalStyle({ mini: open });
  const { confirmDialog } = useDialogs();
  const { t } = useTranslation();
  return (
    <List style={{ paddingTop: 0, width: '100%' }} dense>
      <ListItem
        className={classes.heading}
        onClick={() => setShowLinks(!showLinks)}
      >
        <Tooltip title={!open ? t(title) : ''} placement="right" arrow>
          <ListItemIcon style={{ minWidth: 30 }}>
            <Link />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={t(title)}
          className={classes.headingText}
          style={{
            opacity: open ? 1 : 0,
            marginLeft: 8,
          }}
        />
        <ListItemIcon style={{ minWidth: 30 }}>
          {showLinks ? <Close /> : <Menu />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={showLinks} timeout="auto" unmountOnExit>
        {items && items.map((item, i) => {
          const { label, onClick } = item;
          return (
            <ListItem
              key={`${label}_${i}`}
              button
              onClick={(e) => {
                if (onClick) onClick(e);
                if (closeSidebar) closeSidebar();
                if (item.to) {
                  confirmDialog(
                    <div>
                      {t(
                        'You are about to leave Central and navigate to {{label}}.',
                        { label: t(item.label || '') },
                      )}
                      <br />
                      {t('Do you wish to proceed?')}
                    </div>,
                    t('You are about to leave Central'),
                    t('BACK TO CENTRAL'),
                    t('GO TO SITE'),
                    () => {},
                    () => window.open(item.to),
                  );
                }
              }}
            >
              <Tooltip title={!open && !!label ? label : ''} placement="right" arrow>
                <ListItemIcon
                  style={{
                    minWidth: 30,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.icon ? (
                    item.icon
                  ) : (
                    <img src={icon} className={classes.icon} alt={item.label} />
                  )}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                classes={{ primary: classes.externalLinkText }}
                primary={t(label || '')}
                style={{ marginLeft: 8 }}
              />
            </ListItem>
          );
        })}
      </Collapse>
    </List>
  );
};

export default (props) => (
  <DialogProvider>
    <SidebarExternals {...props} />
  </DialogProvider>
);
