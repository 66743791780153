/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar as MuiAvatar, Icon as MuiIcon } from '@material-ui/core';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[50],
    borderRadius: '20px',
    display: 'flex',
  },
  text: {
    padding: `${theme.spacing(1)}px`,
    flex: '1',
    top: '2px',
    left: '-6px',
    position: 'relative',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export interface ValueAvatarProps {
  className?: string,
  variant?: 'filled' | 'button',
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  icon?: string | React.ReactElement,
  value?: number | string,
  tooltip?: string,
  onClick?: (event?: React.MouseEvent) => void,
  disabled?: boolean,
}

export default function ValueAvatar(props: ValueAvatarProps) {
  const classes = useStyles(props);
  const {
    className, variant = 'button', color = 'primary', icon, value = 0, tooltip, onClick, ...otherProps
  } = props;

  const iconElement = (icon && typeof icon === 'string') ? <MuiIcon>{icon}</MuiIcon> : icon;

  if (variant === 'button') {
    return (
      <Tooltip title={tooltip || ''}>
        <Button variant="text" size="small" disabled={props.disabled} color={color} onClick={onClick}>
          <>{iconElement}</>
          <span style={{ marginLeft: '4px' }}>{value}</span>
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip || ''}>
      <div className={clsx(classes.root, classes[color])} {...otherProps} onClick={onClick}>
        <MuiAvatar className={clsx(classes[color])}>
          {iconElement}
        </MuiAvatar>
        <div className={clsx(classes.text)}>
          {value}
        </div>
      </div>
    </Tooltip>
  );
}

ValueAvatar.defaultProps = {
  variant: 'button',
  color: 'primary',
  value: 0,
};
