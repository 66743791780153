import React, { useState } from 'react';
import {
  ClickAwayListener, type ClickAwayListenerProps, Popper, type PopperProps, Paper,
} from '@material-ui/core';
import { nodeContainsTarget } from './utils/nodeContainsTarget';

const DEFAULT_POPOVER_PROPS: Readonly<Omit<PopperProps, 'children'>> = {
  open: false,
};

interface WithPopperProps<PopperDisplayComponentProps extends {} = {}> {
  popperProps: Omit<PopperProps, 'anchorEl'>;
  popperDisplayComponentProps: PopperDisplayComponentProps;
  onClickAway?: ClickAwayListenerProps['onClickAway'];
}

const withPopper = <PopperDisplayComponentProps extends {}>(
  PopoverDisplayComponent: React.ComponentType<PopperDisplayComponentProps>,
) => <WrappedComponentProps extends React.RefAttributes<HTMLElement>>(
    WrappedComponent: React.ComponentType<WrappedComponentProps>,
  ) => (props: (WrappedComponentProps & WithPopperProps)) => {
      const [ref, setRef] = useState<HTMLElement | null>(null);
      const {
        popperProps = DEFAULT_POPOVER_PROPS,
        popperDisplayComponentProps,
        onClickAway,
        ...wrappedComponentProps
      } = props;

      if (!ref) {
        return (
        // @ts-expect-error -- allowable typing
          <WrappedComponent {...wrappedComponentProps} ref={(elem) => { setRef(elem); }} />
        );
      }

      return (
        <>
          <WrappedComponent {...(wrappedComponentProps as unknown as WrappedComponentProps)} ref={(elem) => { setRef(elem); }} />
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(e) => {
              if (!onClickAway || e.target === ref || nodeContainsTarget(ref, e.target)) {
                return;
              }

              onClickAway(e);
            }}
          >
            <Popper
              {...DEFAULT_POPOVER_PROPS}
              {...popperProps}
              anchorEl={ref}
            >
              <Paper elevation={6}>
                <PopoverDisplayComponent {...popperDisplayComponentProps as PopperDisplayComponentProps} />
              </Paper>
            </Popper>
          </ClickAwayListener>
        </>
      );
    };

export {
  withPopper,
  type WithPopperProps,
};
