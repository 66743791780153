/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import { Gavel } from '@material-ui/icons';
import {
  Box, Menu, MenuItem, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';

const useStyles = makeStyles((theme: any) => createStyles({
  versionDisplay: {
    fontSize: '.6rem',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    padding: '0.2rem',
    margin: '0.1rem',
    textTransform: 'none',
    fontSize: '0.6rem',
  },
  terms: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    margin: '0.1rem',
    textTransform: 'none',
    fontSize: '0.6rem',
    fontWeight: 'bold',
  },
}));

interface LegalProps {
  open: boolean,
  onLegalLinkClick?: (link: 'termsOfUse' | 'termsOfService' | 'privacyPolicy') => void
  termsOfUsePdf?: string,
  privacyPolicyPdf?: string,
  termsOfServicePdf?: string,
}

export default function Legal({
  open, termsOfUsePdf, privacyPolicyPdf, onLegalLinkClick = () => {},
}: LegalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onTermsOfUseClick = () => {
    if (!termsOfUsePdf) {
      onLegalLinkClick('termsOfUse');
    }
  };

  const onPrivacyPolicyClick = () => {
    if (!privacyPolicyPdf) {
      onLegalLinkClick('privacyPolicy');
    }
  };

  const data = (
    <Box display="flex" maxWidth="100%">
      <Typography
        className={classes.terms}
        onClick={onTermsOfUseClick}
      >
        {t('terms_of_use_short_label')}
      </Typography>
      <span className={classes.terms} style={{ margin: 0, marginTop: 2, color: '#99999999' }}>
        •
      </span>
      <Typography
        className={classes.terms}
        onClick={onPrivacyPolicyClick}
      >
        {t('privacy_policy_short_label')}
      </Typography>
    </Box>
  );
  return (
    <div className={classes.versionDisplay}>
      {open ? (
        <Fade in={open}>
          {data}
        </Fade>
      ) : (
        <Tooltip title={t('Terms of Use & Privacy Policy')}>
          <Fade in={!open}>
            <div>
              <Gavel
                style={{
                  cursor: 'pointer', marginLeft: 10, marginTop: 5, opacity: 0.5,
                }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              />
            </div>
          </Fade>
        </Tooltip>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onTermsOfUseClick}>{t('Terms of Use')}</MenuItem>
        <MenuItem onClick={onPrivacyPolicyClick}>{t('Privacy Policy')}</MenuItem>
      </Menu>
    </div>
  );
}
