import React, { useEffect, useState } from 'react';
import { Popper, Typography } from '@material-ui/core';
import { Close, DeleteOutline, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import { ThemeType } from 'types/ThemeType';
import IconButton from 'components/IconButton/IconButton';
import Button from 'components/Button/Button';
import Calendar from './Calendar';
import { DateRangeValue } from './Types';

const useStyles = makeStyles((theme: ThemeType) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
    borderRadius: '4px',
    border: '0px',
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
  },
  calendar: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    paddingLeft: '10px',
    marginLeft: '10px',
  },
}));

interface Props {
  value?: DateRangeValue;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onChange: (value: DateRangeValue) => void;
}

const getMonthYear = (action: string, month: number, year: number) => {
  if (action === 'prev') {
    if (month === 0) return [11, year - 1];
    return [month - 1, year];
  }

  if (action === 'next') {
    if (month === 11) return [0, year + 1];
    return [month + 1, year];
  }

  return [month, year];
};

function DateRange({ value, open, anchorEl, onClose: closeHandler, onChange }: Props) {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const YEAR = new Date().getFullYear();
  const MONTH = new Date().getMonth();
  const NEXT_MONTH_DATE = new Date(YEAR, MONTH + 2, 0);

  const [currentMonth, setCurrentMonth] = useState<number>(MONTH);
  const [currentYear, setCurrentYear] = useState<number>(YEAR);
  const [nextMonth, setNextMonth] = useState<number>(NEXT_MONTH_DATE.getMonth());
  const [nextYear, setNextYear] = useState<number>(NEXT_MONTH_DATE.getFullYear());

  const [hoverCell, setHoverCell] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRangeValue>(value || { from: '', to: '' });

  const changeMonth = (accion: string) => {
    const [cm, cy] = getMonthYear(accion, currentMonth, currentYear);
    const [nm, ny] = getMonthYear(accion, nextMonth, nextYear);

    setCurrentMonth(cm);
    setCurrentYear(cy);

    setNextMonth(nm);
    setNextYear(ny);
  };

  useEffect(() => {
    if (!value) return;
    setDateRange(value);
  }, [value]);

  moment.locale(i18n.language);
  moment.updateLocale(i18n.language, { invalidDate: '-' });

  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
      <div className={styles.dialog}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton aria-label="Preview" onClick={() => changeMonth('prev')}>
                <NavigateBefore fontSize="small" />
              </IconButton>
              <Typography variant="body1" align="center" style={{ flex: 1 }}>
                {`${moment.localeData().months()[currentMonth]} ${currentYear}`}
              </Typography>
              <IconButton />
            </div>
            <Calendar
              year={currentYear}
              month={currentMonth + 1}
              value={dateRange}
              onChangeDate={setDateRange}
              hoverCell={hoverCell}
              setHoverCell={setHoverCell}
            />
          </div>
          <div className={styles.calendar}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton />
              <Typography variant="body1" align="center" style={{ flex: 1 }}>
                {`${moment.localeData().months()[nextMonth]} ${nextYear}`}
              </Typography>
              <IconButton aria-label="Preview" onClick={() => changeMonth('next')}>
                <NavigateNext fontSize="small" />
              </IconButton>
            </div>
            <Calendar
              year={nextYear}
              month={nextMonth + 1}
              value={dateRange}
              onChangeDate={setDateRange}
              hoverCell={hoverCell}
              setHoverCell={setHoverCell}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ flex: 1, height: '25px' }}
            onClick={() => {
              if (dateRange.from && dateRange.to) {
                onChange(dateRange);
                closeHandler();
              }
            }}
          >
            <b>
              {dateRange.from === '' && dateRange.to === ''
                ? t('Select a valid date.')
                : `From: ${moment(dateRange.from).format('MMM D YYYY').toString()} To: ${moment(dateRange.to).format('MMM D YYYY').toString()}`}
            </b>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: '5px', height: '25px' }}
            onClick={() => {
              setDateRange({ from: '', to: '' });
            }}
          >
            <DeleteOutline />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: '5px', height: '25px' }}
            onClick={() => {
              if (closeHandler) closeHandler();
            }}
          >
            <Close />
          </Button>
        </div>
      </div>
    </Popper>
  );
}

export default DateRange;
