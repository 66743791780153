import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar as MuiAppBar, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import { ThemeType } from 'types/ThemeType';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme: ThemeType) => ({
  appBar: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolBar: {
    height: '4rem',
    minHeight: 'auto',
  },
}));

let scrolledBar = window.pageYOffset > 20;

function setScrollbarShadow() {
  const navBar = document.getElementsByClassName('shadow-when-scrolling') as HTMLCollectionOf<HTMLElement>;
  if (navBar.length === 0) return;
  navBar[0].style.boxShadow = scrolledBar ? '#00000046 0 0 7px' : '';
}

window.onscroll = () => {
  const changed = (window.pageYOffset > 20) !== scrolledBar;
  if (!changed) return;
  scrolledBar = !scrolledBar;
  setScrollbarShadow();
};

interface AppBarProps extends MuiAppBarProps {
  className?: string,
  children?: React.ReactNode,
  fixed?: boolean,
}

export default function AppBar(props: AppBarProps) {
  const {
    children, className, fixed, ...otherProps
  } = props;
  const classes = useStyles(props);
  let scrollClassName = '';
  if (fixed) {
    if (!otherProps.style) otherProps.style = {};
    otherProps.style.position = 'fixed';
    scrollClassName = ' shadow-when-scrolling';
    setTimeout(setScrollbarShadow, 1);
  }

  const height = props.style ? props.style.height || undefined : undefined;
  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.appBar, className) + scrollClassName}
      {...otherProps}
    >
      <Toolbar className={classes.toolBar} style={{ height }}>
        {children}
      </Toolbar>
    </MuiAppBar>
  );
}
