import { useContext } from 'react';
import DialogContext from './DialogContext';
import type { WithDialogsProps } from './withDialogs';

export default ():WithDialogsProps => {
  const {
    handleMessageDialog,
    handleConfirmDialog,
    handleAlertDialog,
    handleErrorDialog,
    handleLoadingDialog,
  } = useContext(DialogContext);

  return {
    messageDialog: handleMessageDialog,
    confirmDialog: handleConfirmDialog,
    alertDialog: handleAlertDialog,
    errorDialog: handleErrorDialog,
    loadingDialog: handleLoadingDialog,
  };
};
