import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { Warning as WarningIcon, Error as ErrorIcon, Clear as ClearIcon } from '@material-ui/icons';
import clsx from 'clsx';
import type { ThemeType } from 'types/ThemeType';
import Button from 'components/Button/Button';
import LoadingBounce from 'components/LoadingBounce/LoadingBounce';
import type { DialogTypeProps } from './DialogProvider';
import { DialogType } from './DialogType';
import { usePhoneView } from '../../hooks/usePhoneView';

const Draggable = React.lazy(() => import('react-draggable'));

const baseStyles = makeStyles((theme: ThemeType) => ({
  flex: {
    flex: 1,
  },
  static: {
    flex: 0,
  },
  icon: {
    position: 'relative',
    top: theme.spacing(0.5),
  },
  closeIcon: {
    marginLeft: theme.spacing(2),
  },
  clear: {
    cursor: 'pointer',
    borderRadius: `${theme.shape.borderRadius}px`,
    height: '24px',
    width: '24px',
    '&:hover': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.action.active,
    },
  },
  title: {
    cursor: 'move',
    display: 'flex',
    fontWeight: 500,
    lineHeight: '1.6',
  },
}));

const alertStyles = makeStyles((theme: ThemeType) => ({
  title: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const errorStyles = makeStyles((theme: ThemeType) => ({
  title: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const textStyles = makeStyles(() => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: '0px',
    },
  },
}));

function PaperComponent(props) {
  return (
    <React.Suspense fallback={<LoadingBounce />}>
      {/* @ts-expect-error -- false positive - draggableprops doesn't define children prop */}
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    </React.Suspense>
  );
}

function formatContent(content) {
  const textStyle = textStyles();

  if (typeof (content) === 'string') return <DialogContentText>{content}</DialogContentText>;

  if (Array.isArray(content)) {
    return (
      <>
        {content.map((text, index) => (
          <DialogContentText key={index} className={textStyle.root}>{text}</DialogContentText>
        ))}
      </>
    );
  }

  return content;
}

interface DialogItemProps {
  settings: DialogTypeProps,
  onOkClick?: (event?: React.MouseEvent) => void,
  onCancelClick?: (event?: React.MouseEvent) => void,
  onClose?: any,
}

export default function DialogItem(props: DialogItemProps) {
  const phoneView = usePhoneView();
  const {
    settings, onOkClick, onCancelClick, onClose, ...otherProps
  } = props;

  const {
    dialogType, open = true, title, content, okButton, cancelButton, hideCloseIcon, ...otherSettings
  } = settings;

  delete otherSettings.onOkClick;
  delete otherSettings.onCancelClick;
  delete otherSettings.onClose;

  const base = baseStyles(props);
  const alert = alertStyles(props);
  const error = errorStyles(props);

  switch (dialogType) {
    case DialogType.CONFIRM:
      return (
        <Dialog
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          scroll="paper"
          aria-labelledby="confirm-dialog-title"
          {...otherProps}
          {...otherSettings}
        >
          <DialogTitle className={clsx(base.title)} disableTypography>
            <span className={clsx(base.flex)} style={{fontSize: phoneView ? '1.1rem' : '1.25rem'}}>
              {title}
            </span>
            {!hideCloseIcon && (
            <span className={clsx(base.icon, base.closeIcon, base.static, base.clear)}>
              <ClearIcon onClick={onClose} />
            </span>
            )}
          </DialogTitle>
          <DialogContent>
            {formatContent(content)}
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={onOkClick} autoFocus size='small'><>{okButton}</></Button>
            <Button color="danger" onClick={onCancelClick} size='small'><>{cancelButton}</></Button>
          </DialogActions>
        </Dialog>
      );

    case DialogType.ALERT:
      return (
        <Dialog
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          scroll="paper"
          aria-labelledby="alert-dialog-title"
          {...otherProps}
          {...otherSettings}
        >
          <DialogTitle className={clsx(base.title, alert.title)} disableTypography>
            <span className={clsx(base.flex)}>
              <WarningIcon className={clsx(base.icon, alert.icon)} />
              {title}
            </span>
            {!hideCloseIcon && (
            <span className={clsx(base.icon, base.closeIcon, base.static, base.clear)}>
              <ClearIcon onClick={onClose} />
            </span>
            )}
          </DialogTitle>
          <DialogContent>
            {formatContent(content)}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onOkClick} autoFocus><>{okButton}</></Button>
          </DialogActions>
        </Dialog>
      );

    case DialogType.ERROR:
      return (
        <Dialog
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          scroll="paper"
          aria-labelledby="error-dialog-title"
          {...otherProps}
          {...otherSettings}
        >
          <DialogTitle className={clsx(base.title, error.title)} disableTypography>
            <span className={clsx(base.flex)}>
              <ErrorIcon className={clsx(base.icon, error.icon)} />
              {title}
            </span>
            {!hideCloseIcon && (
            <span className={clsx(base.icon, base.closeIcon, base.static, base.clear)}>
              <ClearIcon onClick={onClose} />
            </span>
            )}
          </DialogTitle>
          <DialogContent>
            {formatContent(content)}
          </DialogContent>
          <DialogActions>
            <Button color="danger" onClick={onOkClick} autoFocus><>{okButton}</></Button>
          </DialogActions>
        </Dialog>
      );

    default:
      return (
        <Dialog
          open={open}
          onClose={onClose}
          PaperComponent={PaperComponent}
          scroll="paper"
          aria-labelledby="dialog-title"
          {...otherProps}
          {...otherSettings}
        >
          {title && (
          <DialogTitle className={clsx(base.title)} disableTypography>
            <span className={clsx(base.flex)} style={{fontSize: phoneView ? '1.1rem' : '1.25rem'}}>
              {title}
            </span>
            {!hideCloseIcon && (
            <span className={clsx(base.icon, base.closeIcon, base.static, base.clear)}>
              <ClearIcon onClick={onClose} />
            </span>
            )}
          </DialogTitle>
          )}
          <DialogContent>
            {formatContent(content)}
          </DialogContent>
          {okButton && (
          <DialogActions>
            <Button color="default" onClick={onOkClick} autoFocus><>{okButton}</></Button>
          </DialogActions>
          )}
        </Dialog>
      );
  }
}
