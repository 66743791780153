import { makeStyles } from '@material-ui/core/styles';

const useAccountDisplayStyles = makeStyles((theme) => {
  return {
    detailsContainer: {
      marginLeft: theme.spacing(4),
    },
    boldFont: {
      fontWeight: 'bold',
    },
    detailsLine: {
      margin: 0,
    },
  };
});

export {
  useAccountDisplayStyles,
};
