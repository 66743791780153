import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ViewList, Apps } from '@material-ui/icons';
import Button from '../Button/Button';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useTheme } from '@material-ui/core/styles';
import type { ThemeType } from '../../types/ThemeType';

const OPACITY = {
  dark: 0.1,
  light: 0.2,
};

const useStyles = makeStyles((theme: ThemeType) => ({
  btn: {
    padding: 0,
    margin: 0,
    background: 'none',
    textTransform: 'none',
    color: 'inherit',
    border: 'none',
    width: '104px',
    '&:hover': {
      color: 'inherit',
      background: 'none',
      textDecoration: 'none',
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: '4px',
    '& div:first-child': {
      borderRight: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    width: '34px',
    overflow: 'hidden',
    transition: 'width .3s ease',
    '&--active': {
      width: '68px',
      backgroundColor: fade(theme.palette.primary.main, OPACITY[theme.palette.type]),
    },
  },
  text: {
    display: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    '&--active': {
      display: 'block',
    },
  },
}));

export interface ViewSwitcherProps {
  initialState?: boolean;
  onToggle?: (active: boolean) => void;
  toggle?: boolean;
}

export const ViewSwitcher = (props: ViewSwitcherProps) => {
  const {
    onToggle,
    toggle,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [active, setActive] = useState<boolean>(!!toggle);

  const handleClick = () => {
    setActive(!active);
    if (onToggle) onToggle(!active);
  };

  useEffect(() => {
    setActive(!!toggle);
  }, [toggle]);

  return (
    <Button className={classes.btn} onClick={handleClick}>
      <div className={classes.root}>
        <div className={clsx(classes.option, { [`${classes.option}--active`]: active })}>
          <ViewList
            style={{
              color: active ? theme.palette.primary.dark : theme.palette.text.disabled,
            }}
          />
          <span className={clsx(classes.text, { [`${classes.text}--active`]: active })}>List</span>
        </div>
        <div className={clsx(classes.option, { [`${classes.option}--active`]: !active })}>
          <Apps
            style={{
              color: !active ? theme.palette.primary.dark : theme.palette.text.disabled,
            }}
          />
          <span className={clsx(classes.text, { [`${classes.text}--active`]: !active })}>Grid</span>
        </div>
      </div>
    </Button>
  );
};
