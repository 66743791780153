import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { Tune } from '@material-ui/icons';
import {
  FormControlLabel,
  Grid,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  Theme,
  Divider,
  Slide,
  ClickAwayListener,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import type { TransitionProps } from '@material-ui/core/transitions';
import type { SelectOption } from './ListingFilterSelect';
import type { Sort } from './ListSort';
import { usePhoneView } from '../../hooks/usePhoneView';
import useURLHashParams from './hashParameters';
import { ListingFilter } from './ListingFilter';

export class FilterGroup {
  filters: ListingFilter[];

  values: Map<ListingFilter, string>;

  onExecute: ((value: string) => void) | undefined;

  constructor(filters: ListingFilter[], onExecute?: (value: string) => void) {
    this.filters = filters;
    this.onExecute = onExecute;
    this.values = new Map();
    this.filters.forEach((f) => {
      if (!f.defaultOption) return;
      this.values.set(f, f.defaultOption.value);
    });
  }

  getFilterByLabel(label: string) {
    for (let i = 0; i < this.filters.length; i += 1) {
      if (this.filters[i].label === label) return this.filters[i];
    }
    return null;
  }

  filter(rows: any[], callback?: (filteredRows: any[]) => void) {
    let res = rows;
    this.filters.forEach((f) => {
      if (!this.values.has(f)) return;
      f.execute(this.values.get(f) || '', res, (filteredRows) => {
        res = filteredRows;
      });
    });
    if (res === rows) res = [...rows];
    if (callback) callback(res);
  }

  execute(filter: ListingFilter, value: string, rows?: any[], callback?: (filteredRows: any[]) => void) {
    this.values.set(filter, value);
    if (rows) this.filter(rows, callback);
    if (this.onExecute) this.onExecute(value);
  }
}

interface LocalFilterGroupRenderProps<T> {
  rows: T[];
  filteredRows: T[];
  setFilteredRows: (rows: T[]) => void;
  filterGroup: FilterGroup;
  style?: React.CSSProperties;
}

export function LocalFilterGroupRenderer<T>(props: LocalFilterGroupRenderProps<T>) {
  const hashParams = useURLHashParams();

  useEffect(() => {
    props.filterGroup.filters.forEach((f) => f.loadHashValue(props.filterGroup, hashParams, props.rows, props.setFilteredRows));
  }, [props]);

  if (props.rows && props.setFilteredRows && props.filteredRows === props.rows) {
    props.filterGroup.filter(props.rows, props.setFilteredRows);
  }
  return (
    <>
      {props.filterGroup.filters.map((filter, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div style={props.style ? props.style : { float: 'left', marginRight: 8 }} key={`listFilter-${index}`}>
          {filter.render(props.filterGroup, hashParams, props.rows, props.setFilteredRows)}
        </div>
      ))}
    </>
  );
}

interface RemoteFilterGroupRenderProps {
  filterGroup?: FilterGroup;
  listingSort?: Sort;
  style?: React.CSSProperties;
  phoneViewStyle?: React.CSSProperties;
}

const useRemoteFilterGroupRendererStyles = makeStyles((theme: Theme) => ({
  filterButton: {
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filterPaper: {
    padding: '15px',
  },
  filterPopper: {
    width: '100%',
    maxWidth: '100%',
    bottom: 0,
    borderRadius: '15px 15px 0 0',
  },
  filterContainer: {
    textAlign: 'right',
    marginTop: '5px',
    height: '22px',
  },
  filterTitle: {
    fontSize: '1.1rem',
  },
  filterTitleContainer: {
    backgroundColor: theme.palette.background.default,
    padding: '5px',
  },
}));

const RadioButton = ({ value, label }: { value: string; label: string }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel value={value} control={<Radio size="small" color="primary" />} label={<Typography variant="caption">{t(label)}</Typography>} />
  );
};

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export function RemoteFilterGroupRenderer(props: RemoteFilterGroupRenderProps) {
  const hashParams = useURLHashParams();
  const classes = useRemoteFilterGroupRendererStyles();
  const phoneView = usePhoneView();
  const { filterGroup, listingSort, style, phoneViewStyle } = props;

  useEffect(() => {
    filterGroup?.filters.forEach((f) => f.loadHashValue(filterGroup, hashParams));
  }, [filterGroup]);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(listingSort?.defaultSortOption);
  const [sortDirection, setSortDirection] = useState(listingSort?.defaultSortDirection);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleResetClick = () => {
    setSortDirection(listingSort?.defaultSortDirection);
    setSortBy(listingSort?.defaultSortOption);
    filterGroup?.filters.forEach((filter: ListingFilter) => {
      const value = filter.defaultValue;
      filter.onExecute(value);
      filterGroup.execute(filter, value || '');
      filter.hashParam && hashParams.setHashValue(filter.hashParam, value);
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popper' : undefined;

  useEffect(() => {
    listingSort?.onExecute({ sortBy: sortBy!, sortDirection: sortDirection! });
  }, [sortBy, sortDirection]);

  const handleSortDirectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortDirection((event.target as HTMLInputElement).value);
  };

  const handleSortByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy((event.target as HTMLInputElement).value);
  };

  return (
    <>
      {phoneView ? (
        <div style={phoneViewStyle} className={!phoneViewStyle ? classes.filterContainer : ''}>
          <Button
            className={classes.filterButton}
            variant="outlined"
            size="small"
            color="primary"
            aria-describedby={id}
            onClick={handleClick}
            startIcon={<Tune />}
          >
            {t('Filters')}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorReference="none"
            classes={{
              paper: classes.filterPopper,
            }}
            TransitionComponent={Transition}
            transitionDuration={200}
          >
            <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => setAnchorEl(null)}>
              <Box>
                <Grid container className={classes.filterTitleContainer}>
                  <Grid item xs={4} style={{ textAlign: 'left' }}>
                    <Button className={classes.filterButton} variant="outlined" size="small" color="primary" onClick={handleResetClick}>
                      {t('Reset')}
                    </Button>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography variant="h6" className={classes.filterTitle}>{`${t('Filters')}`}</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Button className={classes.filterButton} variant="outlined" size="small" color="primary" onClick={handleClick}>
                      {t('Done')}
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
                <Paper className={classes.filterPaper}>
                  {listingSort && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">{`${t('Sort direction')}:`}</Typography>
                        <RadioGroup aria-label="sort-by" value={sortDirection} onChange={handleSortDirectionChange}>
                          <RadioButton value="ASC" label={t('Ascending')} />
                          <RadioButton value="DESC" label={t('Descending')} />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: filterGroup?.filters ? '15px' : 0 }}>
                        <Typography variant="subtitle2" color="textSecondary">{`${t('Sort by')}:`}</Typography>
                        <RadioGroup aria-label="sort-by" value={sortBy} onChange={handleSortByChange}>
                          {listingSort.sortOptions.map((option: SelectOption) => (
                            <RadioButton key={option.value} value={option.value} label={t(option.label)} />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  )}
                  {filterGroup?.filters && <Typography variant="subtitle2" color="textSecondary">{`${t('Filter by')}:`}</Typography>}
                  {filterGroup?.filters.map((filter, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div style={style ? style : { float: 'left', marginRight: 8 }} key={`listFilter-${index}`}>
                      {filter.render(filterGroup, hashParams)}
                    </div>
                  ))}
                </Paper>
              </Box>
            </ClickAwayListener>
          </Popover>
        </div>
      ) : (
        <>
          {filterGroup?.filters.map((filter, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div style={style ? style : { float: 'left', marginRight: 8 }} key={`listFilter-${index}`}>
              {filter.render(filterGroup, hashParams)}
            </div>
          ))}
        </>
      )}
    </>
  );
}
