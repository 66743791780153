import { MICHELIN_DESIGN_SYSTEM_COLORS } from "themes/michelin/createMichelinThemeOptions/michelinDesignSystemConstants";

const { ERROR_RED } = MICHELIN_DESIGN_SYSTEM_COLORS.LIGHT;

const NON_BRAND_COLORS = {
  ERROR_RED,
};

export {
  NON_BRAND_COLORS,
};
