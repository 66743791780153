import { getMichelinLogoPath } from 'components/MichelinLogo/getMichelinLogoPath';
import { getFavicon } from 'utilities/getFavicon';

const createGray = (percentage: number) => {
  if (percentage < 10 || percentage > 85) {
    throw new TypeError('UNSUPPORTED GRAY COLOR');
  }

  const rgbVal = Math.round(255 * (percentage / 100));

  return `rgb(${rgbVal}, ${rgbVal}, ${rgbVal})`;
};

const createPrimaryWhite = (percentage: number = 100) => {
  if (percentage < 0 || percentage > 100) throw new TypeError('UNSUPPORTED PERCENTAGE');

  const rgbVal = Math.round(255 * (percentage / 100));

  return `rgb(${rgbVal}, ${rgbVal}, ${rgbVal})`;
};

// cta - call to action only primaries

const BRAND_CENTER = {
  colors: {
    primary: {
      michelinBlue: '#27509b',
      michelinYellow: '#fce500',
      white: createPrimaryWhite,
      /**
       * reserved for the outline of the Michelin Man, the logos themselves,
       * and the implementation of the "In Motion" graphic style
       */
      black: '#000000',
      gray: createGray,
    },
    secondary: {
      michelinDarkBlue: '#00205b',
      generousGreen: '#a4d65e',
      sustainableGrey: '#53565a',
      purpleEngaged: '#582c83',
    },
    text: {
      michelinBlue: '#27509b',
      white: '#ffffff',
      gray: createGray,
    },
    universe: {
      gastronomyRed: '#bd2333',
      viaMichelinSkyBlue: '#1095f9',
      travelGreen: '#17a78f',
    },
  },
  typefaces: {
    titles_claims_taglines: 'Michelin',
    body: {
      default: 'OpenSans, Arial, sans-serif',
    },
  },
  logos: {
    default: () => getMichelinLogoPath({
      direction: 'stacked',
    }),
    commercial: {
      whiteBg: (horizontal?: boolean) => getMichelinLogoPath({
        direction: horizontal ? 'horizontal' : 'stacked',
      }),
    },
    /**
     * may not be necessary for our projects
     */
    // group: {
    // },
  },
  favicons: {
    commercial: getFavicon('commercial'),
    corporate: getFavicon('corporate'),
  },
} as const;

export default BRAND_CENTER;
