import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ThemeType } from 'types/ThemeType';
import { acidForwardRef } from '../../utilities/forwardRef';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    flex: '1 1 0 %',
    backgroundColor: theme.palette.background.default,
    padding: '0px',
  },
}));

interface PageProps {
  className?: string,
  children?: React.ReactNode,
  height?: CSSProperties['height'],
}

const Page = acidForwardRef<HTMLElement, PageProps>((props, ref) => {
  const classes = useStyles(props);
  const { children, className, height, ...otherProps } = props;

  return (
    <section className={clsx(className, classes.root)} style={{ height }} {...otherProps} ref={ref as React.RefObject<HTMLElement>}>
      {children}
    </section>
  );
});

export default Page;
