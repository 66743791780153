import type { AccountAvatarProps } from 'components/AccountAvatar/AccountAvatar';

export interface GetAddressDisplayParams {
  isMdScreen: boolean;
  isSmScreen: boolean;
}

export const getAddressDisplay = (params: GetAddressDisplayParams): AccountAvatarProps['addressDisplay'] => {
  const {
    isMdScreen,
    isSmScreen,
  } = params;

  if (isSmScreen) return 'none';
  if (isMdScreen) return 'cityState';

  return 'full';
};
