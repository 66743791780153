import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { _michelinOld } from 'themes/michelin/_oldTheme';
import { AcidThemeOptions } from '../types/AcidThemeOptions';

/**
 * intentional dangle -- deprecated theme
 */
// eslint-disable-next-line no-underscore-dangle
export const _createOldMichelinTheme = (options: AcidThemeOptions | undefined) => {
  const muiThemeOptions: ThemeOptions = {
    palette: {
      ...(options?.palette ?? {}),
      type: 'light',
    },
  };

  return _michelinOld(muiThemeOptions);
};
