import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export default function SidebarHeader({
  open = true, handleDrawerClose, handleDrawerOpen, title = '',
}) {
  return (
    <Grid
      container
      alignItems="center"
      style={{
        height: 52,
        minHeight: 52,
        textAlign: open ? 'right' : 'center',
        cursor: 'pointer',
        flexWrap: 'nowrap',

      }}
    >
      <Grid
        item
        md={open ? 8 : undefined}
        style={{
          textAlign: open ? 'right' : 'center',
          transition: 'opacity 225ms linear, width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
          width: open ? '100%' : 0,
          ...(open ? {} : { flexBasis: '0!important' }),
        }}
      >
        <Typography
          align="left"
          style={{
            marginRight: 10,
            whiteSpace: 'nowrap',
            transition: 'opacity 225ms linear, width 225ms cubic-bezier(0.4, 0, 0.6, 1)',
            width: open ? '100%' : 0,
            opacity: open ? 1 : 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginLeft: 18,
          }}
          variant="body2"
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        md={open ? 4 : 12}
        style={{
          textAlign: open ? 'right' : 'center',
        }}
      >
      </Grid>
    </Grid>
  );
}
