import React from 'react';

type PropsTransformer<TInputProps extends {}, TTransformedProps extends {}> = (inputProps: TInputProps) => TTransformedProps;

const withPropsTransformer = <TInputProps extends {}, TTransformedProps extends {}>(propsTransformer: PropsTransformer<TInputProps, TTransformedProps>) => {
  return (WrappedComponent: React.ComponentType<TTransformedProps>) => {
    return (props: TInputProps) => {
      const transformedProps = propsTransformer(props);

      return (
        <WrappedComponent {...transformedProps} />
      );
    };
  };
};

export {
  withPropsTransformer,
  type PropsTransformer,
};
