import { useState, useEffect } from 'react';

type HashChangeListener = (i:number)=>void;

class URLHashStore {
  public static map?: URLSearchParams;
  private static listeners: Set<HashChangeListener> = new Set();
  private static updatesCounter = 0;
  private static lastHREF: string = '';

  static update() {
    if (window.location.href === URLHashStore.lastHREF) return;
    URLHashStore.lastHREF = window.location.href;
    URLHashStore.updatesCounter++;
    URLHashStore.map = new URLSearchParams(window.location.hash.split('#', 2).pop() || '');
    URLHashStore.listeners.forEach((f) => f(URLHashStore.updatesCounter));
  }

  static getHashValue(k: string): string|undefined {
    URLHashStore.update();
    return URLHashStore.map?.get(k) || undefined;
  }

  static setHashValue(k: string, v: string|undefined): string|undefined {
    URLHashStore.update();
    const current = URLHashStore.getHashValue(k);
    if (current === v) return v;
    if (v) {
      URLHashStore.map?.set(k, v);
    } else {
      URLHashStore.map?.delete(k);
    }
    window.location.hash = URLHashStore.map?.toString() || '';
    return v;
  }

  static addListener(listener:HashChangeListener) {
    this.listeners.add(listener);
  }

  static removeListener(listener:HashChangeListener) {
    this.listeners.delete(listener);
  }
}

URLHashStore.update();
window.addEventListener('hashchange', URLHashStore.update);

export interface URLHashParamsManager {
  getHashValue: (key:string) => string|undefined;
  setHashValue: (key:string, value:string|undefined) => string|undefined;
}

export default function useURLHashParams():URLHashParamsManager {
  const [, setCounter] = useState(0);
  useEffect(() => {
    URLHashStore.addListener(setCounter);
    return () => URLHashStore.removeListener(setCounter);
  });
  return {
    getHashValue: URLHashStore.getHashValue,
    setHashValue: URLHashStore.setHashValue,
  };
}
