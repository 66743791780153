import BRAND_CENTER from 'themes/brandCenter/brandCenterConstants';
import type { AcidTheme } from 'themes/ThemeDefinitions';
import { MaestroLogo } from 'utilities/createTheme/logos/MaestroLogo';

export const getLogoPrimary = (type: AcidTheme) => {
  switch (type) {
    case 'light':
      return MaestroLogo.LIGHT;
    case 'dark':
      return MaestroLogo.DARK;
    case 'michelinLight':
    case 'michelinLightBC':
    case 'michelinBrandCenter':
      return BRAND_CENTER.logos.default();
    case 'michelinDark':
      return BRAND_CENTER.logos.default();
    default:
      return MaestroLogo.DEFAULT;
  }
};
