import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography } from '@material-ui/core';
import { WarningRounded as WarningRoundedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { ThemeType } from 'types/ThemeType';
import { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';

interface MessageProps extends MuiPaperProps {
  className?: string,
  title?: string,
  message?: string,
  icon?: React.ReactElement,
  onClick?: (event?: React.MouseEvent) => void,
  children?: React.ReactNode,
}

const paperStyles = makeStyles((theme: ThemeType) => ({
  root: () => ({
    alignSelf: 'center',
    padding: theme.spacing(3),
    textAlign: 'center',
    minHeight: '12rem',
  }),
  title: () => ({
    color: theme.palette.text.secondary,
    fontWeight: 'bolder',
    textShadow: `2px 2px 4px ${theme.palette.text.disabled}`,
  }),
}));

const iconStyles = makeStyles(() => ({
  root: () => ({
    position: 'absolute',
    top: 'calc(50% - 5rem)',
    left: 'calc(50% - 5rem)',
    height: '10rem',
    opacity: '15%',

    '&>svg': {
      fontSize: '10rem',
    },
  }),
}));

export default function Message(props: MessageProps) {
  const paperClasses = paperStyles(props);
  const iconClasses = iconStyles(props);

  const {
    className, icon, title, message, children, ...otherProps
  } = props;

  return (
    <Paper className={clsx(className, paperClasses.root)} {...otherProps}>
      <div className={clsx(iconClasses.root)}>
        {icon || <WarningRoundedIcon />}
      </div>

      <div>
        <Typography variant="h2" className={clsx(paperClasses.title)}>{title}</Typography>
        <Typography variant="body1">{message}</Typography>
        {children}
      </div>
    </Paper>
  );
}
