/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Spinner from '../Spinner/Spinner';
import { Fab as MuiFab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import type { FabProps as MuiFabProps } from '@material-ui/core/Fab';
import type { ThemeType } from 'types/ThemeType';

export interface FabProps extends MuiFabProps {
  label?: string;
  displayDisabled?: boolean;
  labelPosition?: 'left' | 'right';
  loading?: boolean;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    zIndex: theme.zIndex.speedDial,
    height: theme.spacing(7),
    borderRadius: theme.spacing(3.5),
    '&:disabled': {
      backgroundColor: 'rgb(200, 200, 200)',
      color: 'rgb(100, 100, 100)',
    },
  },
  label: (props: any) => ({
    marginRight: props.extended && theme.spacing(0.5),
    marginLeft: props.extended && theme.spacing(0.5),
  }),
}));

export default function Fab(props: FabProps) {
  const [extended, setExtended] = useState(false);
  const classes = useStyles({
    ...props,
    extended,
  });

  const {
    size,
    labelPosition,
    displayDisabled,
    disabled,
    label,
    children,
    loading,
    ...otherProps
  } = props;

  const thisLabel = <span className={classes.label}>{label || props['aria-label']}</span>;
  const theme = useTheme<ThemeType>();
  if (disabled && !loading && !displayDisabled) return null;
  const sizes = {
    small: 40,
    medium: 48,
    large: 56,
  };
  const padding = {
    small: 8,
    medium: 12,
    large: 16,
  };

  const renderLoading = () => (<Spinner size="xs" color={disabled ? theme.palette.text.disabled : theme.palette.primary.main} />);

  const renderChildren = () => {
    if (labelPosition === 'right') {
      return (
        <>
          {loading ? renderLoading() : children}
          {extended && thisLabel}
        </>
      );
    }

    return (
      <>
        {extended && thisLabel}
        {loading ? renderLoading() : children}
      </>
    );
  };

  return (
    <MuiFab
      className={classes.root}
      style={{
        height: size ? sizes[size] : undefined,
        width: size ? (extended && !loading) ? 'auto' : sizes[size] : undefined,
        padding: size ? extended ? padding[size] : 0 : undefined,
      }}
      variant={extended ? 'extended' : 'round'}
      onMouseOver={() => setExtended(!loading)}
      onMouseLeave={() => setExtended(!!loading)}
      disabled={disabled}
      {...otherProps}
    >
      {
        renderChildren()
      }
    </MuiFab>
  );
}

Fab.defaultProps = {
  displayDisabled: false,
  labelPosition: 'left',
};
