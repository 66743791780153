import { maestroLightThemeOptions, maestroDarkThemeOptions } from './maestro';
import { michelinLightThemeOptions, michelinDarkThemeOptions } from './michelin';
import { lightMichelinThemeOptions } from './brandCenter';

const DEFAULT_LIGHT_THEME = maestroLightThemeOptions;
const DEFAULT_DARK_THEME = maestroDarkThemeOptions;

export const THEME_DEFINITIONS = {
  light: DEFAULT_LIGHT_THEME,
  dark: DEFAULT_DARK_THEME,
  michelinLight: michelinLightThemeOptions,
  michelinDark: michelinDarkThemeOptions,
  /**
   * @deprecated use `michelinBrandCenter` instead
   */
  michelinLightBC: lightMichelinThemeOptions,
  michelinBrandCenter: lightMichelinThemeOptions,
  maestroLight: maestroLightThemeOptions,
  maestroDark: maestroDarkThemeOptions,
  michelin: undefined,
} as const;

export type AcidTheme = keyof typeof THEME_DEFINITIONS;

export type MuiTheme = 'light' | 'dark' | 'michelin';

export const ACID_THEMES: readonly AcidTheme[] = Object.keys(THEME_DEFINITIONS) as readonly AcidTheme[];

export const isDefinedTheme = (maybeDefinedTheme: string): maybeDefinedTheme is AcidTheme => maybeDefinedTheme in THEME_DEFINITIONS;
