/* eslint-disable react/prefer-stateless-function */
import React, { type MutableRefObject } from 'react';
import TextField from 'components/TextField/TextField';
import type { PhoneInputProps } from './PhoneInput';

export interface PhoneNumberTextFieldProps extends PhoneInputProps {
  error?: boolean,
  warning?: string|false,
  innerRef: ((instance: any) => void) | MutableRefObject<any> | null,
}

class PhoneNumberTextFieldForwardRef extends React.Component<PhoneNumberTextFieldProps> {
  render() {
    const {
      children, id, label, required, readOnly, error, warning, onChange = () => {}, innerRef, ...rest
    } = this.props;

    const status = (warning) ? 'warning' : 'none';

    return (
      <TextField
        InputProps={{ readOnly }}
        style={{ display: 'flex', flexWrap: 'wrap' }}
        {...rest}
        variant="standard"
        id={id}
        label={label}
        error={error}
        required={required}
        statusIcon={status}
        onChange={(event) => onChange(event.target.value as any)}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ ref: innerRef }}
      >
        <>{children}</>
      </TextField>
    );
  }
}

export default PhoneNumberTextFieldForwardRef;
